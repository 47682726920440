import { inject } from 'aurelia-framework';
import { ApiService } from './api-service';
import { Helper } from 'resources/extensions/helper';

@inject(ApiService, Helper)
export class BlacklistService {
    path = 'Blacklist';
    userBlacklist;

    constructor(api, helper) {
        this.api = api;
        this.helper = helper;
    }

    async getBlacklistByUserEmail(userEmail, updateList = false) {
        if (this.userBlacklist?.length > 0 && !updateList) {
            return this.userBlacklist;
        }
        this.userBlacklist = await this.helper.fetchData(this.api, `${this.path}/${userEmail}/GetByUserEmailProfile`, `BlacklistService/${userEmail}`, updateList);
        return this.userBlacklist;
    }
}
