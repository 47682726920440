export class QueryParamsValueConverter {
    toView(value) {
        if (!value) {
            return;
        }

        const paramArr = value.slice(value.indexOf('?') + 1).split('&');
        const params = {};
        paramArr.map(param => {
            const [key, val] = param.split('=');
            params[key] = decodeURIComponent(val);
        });

        return params;
    }
}
