export const ErrorReason = {
    Unauthorized: 0,
    NotFound: 1,
    MethodNotAllowed: 2,
    TooManyRequests: 3,
    Locked: 4,
    BadRequest: 5,
    ServerError: 6,
    ValidationError: 7,
    InvalidData: 8
};
