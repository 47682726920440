((window, document) => {
    const defaultOptions = {
        minSize: 10,
        maxSize: 30,
        type: 'text',
        content: '&#10052',
        fadeOut: true,
        autoplay: true,
        interval: 200
    };

    const getCssPrefix = (propertyName) => {
        const capitalizePropertyName = propertyName.charAt(0).toUpperCase() + propertyName.slice(1);
        const tempDiv = document.createElement('div');
        const style = tempDiv.style;
        const vendorPrefixes = ['Webkit', 'Moz', 'ms', 'O'];

        if (propertyName in style) return propertyName;

        for (let i = 0; i < vendorPrefixes.length; i++) {
            const name = vendorPrefixes[i] + capitalizePropertyName;
            if (name in style) return name;
        }

        return null;
    };

    const cssPrefixedNames = {
        'transform': getCssPrefix('transform'),
        'transition': getCssPrefix('transition')
    };
    const transitionendEventName = {
        'WebkitTransition': 'webkitTransitionEnd',
        'OTransition': 'oTransitionEnd',
        'Moztransition': 'transitionend',
        'transition': 'transitionend'
    }[cssPrefixedNames.transition];

    window.addEventListener('resize', function() {
        winHeight = window.innerHeight;
        winWidth = window.innerWidth;
    }, false);

    const random = (min, max, deviation) => {
        if (deviation) {
            deviation *= max;
            max = max + deviation;
            min = max - deviation;
        } else {
            min = min || 0;
        }
        return parseInt(Math.random() * (max - min + 1) + min);
    };

    const extend = (target, source) => {
        for (const prop in source) {
            target[prop] = source[prop];
        }
        return target;
    };

    const setStyle = (element, rules) => {
        for (const name in rules) {
            element.style[cssPrefixedNames[name] || name] = rules[name];
        }
    };

    let hidden;

    if (typeof document.hidden !== 'undefined') {
        hidden = 'hidden';
    } else if (typeof document.mozHidden !== 'undefined') {
        hidden = 'mozHidden';
    } else if (typeof document.msHidden !== 'undefined') {
        hidden = 'msHidden';
    } else if (typeof document.webkitHidden !== 'undefined') {
        hidden = 'webkitHidden';
    }

    const visibilityChange = hidden.replace(/hidden/i, 'visibilitychange');

    class Snowfall {
        constructor(newOptions) {
            const _ = this;
            const queue = [];
            const options = extend({}, defaultOptions);
            const $snowfield = document.createElement('div');
            const winWidth = window.innerWidth;
            const winHeight = window.innerHeight;
            let isImage; let cntLength; let $snowflake; let timer;

            const config = (newOptionsAdditional) => {
                extend(options, newOptionsAdditional);

                isImage = options.type === 'image';
                cntLength = options.content.length;

                $snowflake = isImage ? new Image() : document.createElement('div');
                $snowflake.className = 'snowflake snowflake-' + options.type;
                $snowflake.dataset.type = options.type;
            };

            config(newOptions);

            const snowFlake = () => {
                const _$snowflake = $snowflake.cloneNode();
                if (options.type !== 'solid') {
                    _$snowflake[isImage ? 'src' : 'innerHTML'] = typeof options.content === 'string' ? options.content : options.content[cntLength === 0 ? 0 : Math.floor(Math.random() * cntLength)];
                }

                return _$snowflake;
            };

            const snowAnimate = () => {
                const size = random(options.minSize, options.maxSize);
                const top = -2 * size;
                const left = random(0, winWidth - size);
                const opacity = random(5, 10) / 10;
                const angle = random(null, winHeight * 0.8, 1);
                const translateX = random(-100, 100);
                const translateY = winHeight + size * 2;
                const duration = random(null, winHeight * 20, 0.2);
                let _$snowflake;

                if (queue.length) {
                    _$snowflake = queue.shift();
                    if (_$snowflake.dataset.type !== options.type) _$snowflake = snowFlake();
                } else {
                    _$snowflake = snowFlake();
                }

                const styleRules = {
                    'top': top + 'px',
                    'left': left + 'px',
                    'opacity': opacity,
                    'transform': 'none',
                    'transition': duration + 'ms linear'
                };

                switch (options.type) {
                    case 'solid':
                        styleRules.width = styleRules.height = size + 'px';
                        break;
                    case 'text':
                        styleRules['font-size'] = size + 'px';
                        break;
                    case 'image':
                        styleRules.width = size + 'px';
                        break;
                }

                setStyle(_$snowflake, styleRules);

                $snowfield.appendChild(_$snowflake);

                setTimeout(() => {
                    setStyle(_$snowflake, {
                        'transform': 'translate(' + translateX + 'px,' + translateY + 'px) rotate(' + angle + 'deg)',
                        'opacity': options.fadeOut ? 0 : opacity
                    });
                }, 100);
            };

            _.playing = 0;

            _.play = () => {
                if (_.playing) return;
                timer = setInterval(snowAnimate, options.interval);
                _.playing = 1;
            };

            _.stop = () => {
                if (!_.playing) return;
                clearInterval(timer);
                timer = null;
                _.playing = 0;
            };

            document.addEventListener(visibilityChange, () => {
                document[hidden] ? _.stop() : _.play();
            }, false);

            $snowfield.addEventListener(transitionendEventName, (e) => {
                const snowflake = e.target || e.srcElement;
                $snowfield.removeChild(snowflake);
                queue.push(snowflake);
            }, false);

            $snowfield.className = 'snowfield';
            document.body.appendChild($snowfield);

            options.autoplay && _.play();

            return _;
        }
    }

    window.Snowfall = Snowfall;
})(window, document);
