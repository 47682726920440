import { inject } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { SessionService } from './session-service';
import { ToastService } from './toast-service';
import { Helper } from 'resources/extensions/helper';
import { ToastType } from 'resources/helpers/enums';
import { HttpClient } from 'aurelia-fetch-client';

const AUTHORIZATION_HEADER = 'Authorization';

@inject(
    SessionService,
    EventAggregator,
    ToastService,
    Helper,
    HttpClient
)
export class ApiInterceptor {
    /**
     * @param {SessionService} sessionService
     * @param {AureliaEventAggregator} eventAggregator
     * @param {ToastService} toastService
     * @param {Helper} helper
     * @param {HttpClient} httpClient
     */
    constructor(
        sessionService,
        eventAggregator,
        toastService,
        helper,
        httpClient
    ) {
        this.sessionService = sessionService;
        this.eventAggregator = eventAggregator;
        this.toastService = toastService;
        this.helper = helper;
        this.http = httpClient;
    }

    /**
     * @param {Request} request
     * @returns {Promise<Request>}
     */
    async request(request) {
        try {
            if (request.headers.has('X-Skip-Interceptor')) {
                return request;
            }

            const storedToken = await this.sessionService.getToken();

            if (storedToken && !request.headers.get(AUTHORIZATION_HEADER)) {
                const bearerToken = `Bearer ${storedToken}`;
                request.headers.append(AUTHORIZATION_HEADER, bearerToken);
            }

            return request;
        } catch (e) {
            console.log(e);
            this.toastService.showToast(ToastType.ERROR, 'Something went wrong. If this error continues, please contact support.');
        }
    }

    async response(response) {
        try {
            if (response?.status >= 400 && response?.status <= 599) {
                if (response?.status === 404) return;
                if (response?.status === 401) {
                    await this.sessionService.clearSession();
                    if (!response.headers?.get('content-type')?.includes('application/json')) return;
                }
                const data = await response.json();
                let msg = data.Message || data.message || (typeof data === 'string' ? data : undefined);
                if (msg?.includes('withdraw-concurrent-error')) {
                    this.eventAggregator.publish('withdraw-concurrent-error', msg);
                } else if (msg) {
                    msg.endsWith('.') ? msg : msg = msg + '.';
                    this.toastService.showToast(ToastType.ERROR, msg.replace(/(?:\r\n|\r|\n)/g, '<br />'));
                }

                if (data.validationErrors) {
                    let errorMsg = '';
                    Object.keys(data.validationErrors).forEach((key, index) => {
                        data.validationErrors[key].forEach(m => {
                            errorMsg += `${m}<br>`;
                        });
                    });
                    this.toastService.showToast(ToastType.ERROR, errorMsg);
                }
                new Error(msg);
            }
            return response;
        } catch (e) {
            console.log(e);
            this.toastService.showToast(ToastType.ERROR, 'Something went wrong. If this error continues, please contact support.');
        }
    }
}
