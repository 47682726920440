import { inject } from 'aurelia-framework';
import { ProductService } from 'services/product-service';
import { SessionService } from 'services/session-service';
import { Helper } from 'resources/extensions/helper';

@inject(SessionService, ProductService, Helper)
export class PreActivateStep {
    /**
     * @param {SessionService} sessionService
     * @param {ProductService} productService
     * @param {Helper} helper
     */
    constructor(sessionService, productService, helper) {
        this.sessionService = sessionService;
        this.productService = productService;
        this.helper = helper;

        this.staticRoutesWithChildRoutes = {
            'sell': 3,
            'sellv2': 6,
            'customer-portal': 5,
            'blog': 3,
            'blog-category': [3, 2],
            'author': [3, 2],
            'sitemap': 3
        };

        this.staticRoutesWithChildRoutesDefault = [
            'currency',
            'items-game',
            'accounts-game',
            'services',
            'skins-game',
            'swap',
            'unique-names',
            'sign-in',
            'blog-post'
        ];

        this.customerPortalChildRoutes = {
            '': 1,
            'order-details': 3,
            'sold': 4,
            'purchased': 4,
            'swap': 4,
            'security': 3,
            'subscription': 3
        };

        this.customerPortalChildRoutesDefault = [
            'profile',
            'security',
            'verification',
            'balance',
            'support',
            'subscription',
            'purchased',
            'sold',
            'swap',
            'referrals',
            'orders'
        ];

        ['staticRoutesWith', 'customerPortal'].forEach(x => this.mapRanges(x));
    }

    chatRoutes = ['chat', 'products'];

    customerPortalMultiple = {
        'sold': this.chatRoutes,
        'purchased': this.chatRoutes,
        'swap': this.chatRoutes,
        'security': ['withdraw', '2fa', 'reset-password'],
        'subscription': ['cashback', 'payment-details', 'manage-membership', 'cancel-membership']
    };

    childRoute = '';
    routeName = '';
    siteRoutes = ['services', 'cart', 'sell', 'services', 'unique-names', 'contact', 'swap'];
    profileRoutes = [
        'profile',
        'subscription',
        'referrals',
        'verification',
        'purchased',
        'sold',
        'balance',
        'security',
        'support',
        'services',
        'cart',
        'order-details',
        'contact',
        'unique-names',
        'sell',
        'swap'
    ];

    stockProductsRoutes = ['accounts'];
    productRoutes = ['currency', 'sell', 'services', 'swap'];

    mapRanges(name) {
        const multiple = this[`${name}Multiple`];
        if (multiple) {
            const multipleRoutes = [];
            Object.keys(multiple).forEach(root => {
                multiple[root].forEach(child => {
                    multipleRoutes.push(`${root}/${child}`);
                    this[`${name}ChildRoutes`][`${root}-${child}`] = this[`${name}ChildRoutes`][root];
                });
            });
            this[`${name}MultipleRoutes`] = multipleRoutes;
        }

        const routes = this[`${name}ChildRoutes`];
        const def = this[`${name}ChildRoutesDefault`];

        def?.forEach(x => routes[x] = 2);

        Object.keys(routes).forEach(x => {
            let val = routes[x];
            if (typeof val === 'number') val = this.helper.range(val, true);
            else val = this.helper.range(val[0], true, val[1]);
            routes[x] = val;
        });
    }

    run(navigationInstruction, next) {
        this.childRoute = navigationInstruction.params.childRoute;
        this.routeName = navigationInstruction.config.name;

        const profilePath = this.getRouteAction(this.profileRoutes, this.siteRoutes, this.sessionService.destroyProfilePath());
        if (profilePath) this.sessionService.saveProfilePath(profilePath);

        const stockProductsRoute = this.getRouteAction(this.stockProductsRoutes, this.stockProductsRoutes, this.productService.removeStockProductsPath());
        if (stockProductsRoute) this.productService.saveStockProductsPath(stockProductsRoute);

        const productRoute = this.getRouteAction(this.productRoutes, this.productRoutes, this.productService.removeProductPath());
        if (productRoute) this.productService.saveProductPath(productRoute);

        if (navigationInstruction.params.childRoute) {
            const foundRoute = navigationInstruction.router.routes.find(x => x.name === this.routeName);
            const foundRouteCountSlashes = foundRoute?.route?.split('/');
            const currentUrlSlashes = navigationInstruction.fragment.slice(1).split('/');

            if (this.helper.includesSome(this.childRoute, this.customerPortalMultipleRoutes)) {
                this.childRoute = this.childRoute.split('/');
                this.childRoute = this.childRoute.slice(0, 2).join('-');
            } else if (this.childRoute.includes('order-details')) {
                this.childRoute = this.childRoute.split('/')[0];
            }

            if (foundRoute?.name === 'customer-portal' && !this.customerPortalChildRoutes[this.childRoute]?.includes(currentUrlSlashes?.length)) {
                navigationInstruction.router.navigateToRoute('404');
            } else if ((currentUrlSlashes?.length !== foundRouteCountSlashes?.length && !this.staticRoutesWithChildRoutes[this.routeName])
                || (currentUrlSlashes?.length !== foundRouteCountSlashes?.length && this.staticRoutesWithChildRoutes[this.routeName] && !this.staticRoutesWithChildRoutes[this.routeName].includes(currentUrlSlashes?.length))) {
                navigationInstruction.router.navigateToRoute('404');
            }
        }
        return next();
    }

    /**
     * Gets the path from the main or child route according to the current route
     * @param {string[]} siteRoutes
     * @param {string[]} childRoutes
     * @returns {string}
     */
    getRouteFromProfile(childRoutes, siteRoutes) {
        return (childRoutes).find(r => r === (this.getRouteFromSite(siteRoutes) ?? this.childRoute)?.split('/')[0]);
    }

    /**
     * Gets the path according to the current route name
     * @param {string[]} routes
     * @returns {string}
     */
    getRouteFromSite(routes) {
        return (routes).find(r => r === this.routeName);
    }

    /**
     * Gets or removes the path according to the current route
     * @param {string[]} childRoutes
     * @param {string[]} siteRoutes
     * @param destroyFunction
     * @returns {string | null}
     */
    getRouteAction(childRoutes, siteRoutes, destroyFunction) {
        const routeActions = {
            [this.getRouteFromProfile(childRoutes, siteRoutes)]: () => this.getRouteFromProfile(childRoutes, siteRoutes),
            default: () => {
                destroyFunction;
                return null;
            }
        };

        return routeActions[this.getRouteFromProfile(childRoutes, siteRoutes) || 'default']();
    }
}
