import { inject } from 'aurelia-framework';
import { ApiService } from './api-service';
import { Helper } from 'resources/extensions/helper';

@inject(ApiService, Helper)
export class WebsiteService {
    path = 'Website';
    pages;

    constructor(api, helper) {
        this.api = api;
        this.helper = helper;
    }

    async getPagesByWebsiteShortcode() {
        if (!this.pages) this.pages = await this.api.doGet(`${this.path}/Pages?websiteShortCode=CG`);
        return this.pages;
    }

    async getAllBeforeExecution(func) {
        await this.getPagesByWebsiteShortcode();
        return func();
    }

    getPage = async(name) => await this.getAllBeforeExecution(() => this.pages.find(x => x.name.toLowerCase() === name.toLowerCase()));

    getRoute = async(name) => (await this.getPage(name))?.routeName;

    getRouteOrDefault = async(name, def = -1) => (await this.getRoute(name)) ?? (def !== -1 ? def : this.helper.toRoute(name));

    getManyBy = async(_this, names, extra = 'Page', func = this.getPage) => {
        names.forEach(async name => {
            const variableName = `${this.helper.camelize(name)}${extra}`;
            _this[variableName] = await func(name);
        });
    };

    getManyPages = async(_this, names) => await this.getManyBy(_this, names);

    getManyRoutes = async(_this, names) => await this.getManyBy(_this, names, 'PageRoute', this.getRouteOrDefault);

    getPagesAndRoutes = async(_this, names) => {
        await this.getManyPages(_this, names);
        await this.getManyRoutes(_this, names);
    };

    getPagesDictionary = async() => await this.getAllBeforeExecution(() => {
        return this.pages.reduce((a, b) => {
            a[b.name] = b;
            return a;
        }, {});
    });
}
