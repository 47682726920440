import 'regenerator-runtime/runtime';
import { environmentName, apiEndpoint, websiteShortCode } from 'environment';
import { HttpClient } from 'aurelia-fetch-client';
import { ApiInterceptor } from 'services/api-interceptor';
import { PLATFORM } from 'aurelia-pal';
import 'bootstrap';
import '@popperjs/core';
import 'styles/styles.scss';
import '@chicksgroup/web-components';
import storeInitialState from './resources/helpers/state/index';

export function configure(aurelia) {
    aurelia.use
        .standardConfiguration()
        .plugin(PLATFORM.moduleName('aurelia-validation', 'aurelia'))
        .plugin(PLATFORM.moduleName('@aurelia-mdc-web/base', 'aurelia'))
        .plugin(PLATFORM.moduleName('@aurelia-mdc-web/button', 'aurelia'))
        .plugin(PLATFORM.moduleName('@aurelia-mdc-web/checkbox', 'aurelia'))
        .plugin(PLATFORM.moduleName('@aurelia-mdc-web/chips', 'aurelia'))
        .plugin(PLATFORM.moduleName('@aurelia-mdc-web/circular-progress', 'aurelia'))
        .plugin(PLATFORM.moduleName('@aurelia-mdc-web/dialog', 'aurelia'))
        .plugin(PLATFORM.moduleName('@aurelia-mdc-web/drawer', 'aurelia'))
        .plugin(PLATFORM.moduleName('@aurelia-mdc-web/expandable', 'aurelia'))
        .plugin(PLATFORM.moduleName('@aurelia-mdc-web/form-field', 'aurelia'))
        .plugin(PLATFORM.moduleName('@aurelia-mdc-web/icon-button', 'aurelia'))
        .plugin(PLATFORM.moduleName('@aurelia-mdc-web/linear-progress', 'aurelia'))
        .plugin(PLATFORM.moduleName('@aurelia-mdc-web/list', 'aurelia'))
        .plugin(PLATFORM.moduleName('@aurelia-mdc-web/lookup', 'aurelia'))
        .plugin(PLATFORM.moduleName('@aurelia-mdc-web/radio', 'aurelia'))
        .plugin(PLATFORM.moduleName('@aurelia-mdc-web/select', 'aurelia'))
        .plugin(PLATFORM.moduleName('@aurelia-mdc-web/slider', 'aurelia'))
        .plugin(PLATFORM.moduleName('@aurelia-mdc-web/switch', 'aurelia'))
        .plugin(PLATFORM.moduleName('@aurelia-mdc-web/tab-bar', 'aurelia'))
        .plugin(PLATFORM.moduleName('@aurelia-mdc-web/text-field', 'aurelia'))
        .plugin(PLATFORM.moduleName('@aurelia-mdc-web/tooltip', 'aurelia'), (config) => {
            config.scrollHost = '#main-page-host .simplebar-content-wrapper';
            config.showDelay = 0;
            config.hideDelay = 0;
        })
        .plugin(PLATFORM.moduleName('aurelia-store', 'aurelia'), { initialState: storeInitialState, measurePerformance: 'all',
            logDefinitions: {
                dispatchedActions: 'debug',
                performanceLog: 'debug',
                devToolsStatus: 'debug'
            }
        })
        .feature(PLATFORM.moduleName('resources/index', 'aurelia'));

    aurelia.use.developmentLogging(environmentName() === 'local' ? 'debug' : 'none');

    aurelia.container.get(HttpClient).configure(config => {
        config
            .withBaseUrl(apiEndpoint())
            .withInterceptor(aurelia.container.get(ApiInterceptor))
            .withDefaults({
                headers: {
                    'Accept': 'application/json',
                    'websiteShortCode': websiteShortCode()
                }
            });
    });

    aurelia.start().then(() => aurelia.setRoot(PLATFORM.moduleName('app')));
}
