import { inject } from 'aurelia-framework';
import { ApiService } from './api-service';

@inject(ApiService)
export class PriceModificationService {
    path = 'PriceModification';
    priceModifications;

    constructor(api) {
        this.api = api;
    }

    async getPriceModifications() {
        if (!this.priceModifications) {
            this.priceModifications = await this.api.doGet(this.path);
        }
        return this.priceModifications;
    }
}
