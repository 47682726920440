import { inject } from 'aurelia-framework';
import { setupSardine, updateSardineConfig } from '@sardine-ai/web-sdk';
import { SiteSettingService } from 'services/site-setting-service';
import { sardineAiClientId, sardineAiEnvironment, environmentName } from 'environment';

@inject(SiteSettingService)
export class SardineAi {
    constructor(siteSettingsService) {
        this.siteSettingsService = siteSettingsService;
    }

    includeSardineSdk = () => {
        if (!this.sardineAi) {
            this.sardineAi = setupSardine({
                clientId: sardineAiClientId(),
                environment: sardineAiEnvironment()
            });
        }
    };

    updateConfig = (userId, sessionKey, flow, fields) => {
        updateSardineConfig({
            customerId: userId,
            sessionKey: sessionKey,
            flow: flow,
            fields: fields
        });
    };

    handleSardineFlow = async(userId, sessionKey, flow, fields) => {
        this.siteSettings = await this.siteSettingsService.getSiteSettings();
        const sardineAiEnabled = this.siteSettings?.find(x => x.key === 'SardineAiEnabled');
        if (environmentName() === 'local' || (sardineAiEnabled && parseInt(sardineAiEnabled.value) <= 0)) return;
        this.includeSardineSdk();
        this.updateConfig(userId, sessionKey, flow, fields);
    };
}
