import { produce } from 'immer';

/**
 * @type {SellState}
 */
const initialState = {
    selectedProducts: []
};

/**
 * @param {AppState} state
 * @param {'Add' | 'Remove'} action
 * @param {SteamInventoryItem} product
 */
export const setSellSelectedProducts = (state, action, product) => {
    return produce(state, draftState => {
        if (action === 'Add') {
            const index = draftState.sellState.selectedProducts.findIndex(x => x.productId === product.productId && x.assetId === product.assetId);

            if (index === -1) {
                draftState.sellState.selectedProducts.push(product);
            } else {
                draftState.sellState[index] = product;
            }
        } else {
            draftState.sellState.selectedProducts = draftState.sellState.selectedProducts.filter(x => x.productId !== product.productId || x.assetId !== product.assetId);
        }
    });
};

/**
 * @param {AppState} state
 */
export const clearSellProducts = (state) => {
    return produce(state, draftState => {
        draftState.sellState.selectedProducts.length = 0;
    });
};

export default initialState;
