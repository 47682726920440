import simplebar from 'simplebar';

const SimplebarOverride = simplebar;

export default class SimpleBarOverride extends simplebar {
    speed = 10;
    constructor(...args) {
        super(...args);
        SimpleBarOverride.instances.set(args[0], this);
        this.initListeners();
    }

    initListeners() {
        const getElementWindow = (element) => {
            if (!element || !element.ownerDocument || !element.ownerDocument.defaultView) {
                return window;
            }
            return element.ownerDocument.defaultView;
        };

        const elWindow = getElementWindow(this.el);

        this.el.addEventListener('mouseenter', this.onMouseEnter);
        this.el.addEventListener('mousemove', this.onMouseMove);
        this.el.addEventListener('mouseleave', this.onMouseLeave);
        setTimeout(() => {
            this.el.addEventListener('pointerdown', this.onPointerEvent);
        }, 1000);

        ['click', 'dbclick'].forEach((e) => {
            this.el.addEventListener(e, () => {
                this.mouseDown = false;
            });
        });

        this.contentWrapperEl?.addEventListener('scroll', this.onScroll);

        elWindow.addEventListener('resize', this.onWindowResize);

        if (!this.contentEl) return;

        if (window.ResizeObserver) {
            let resizeObserverStarted = false;
            const resizeObserver = elWindow.ResizeObserver || ResizeObserver;
            this.resizeObserver = new resizeObserver(() => {
                if (!resizeObserverStarted) return;

                elWindow.requestAnimationFrame(() => {
                    this.recalculate();
                });
            });

            this.resizeObserver.observe(this.el);
            this.resizeObserver.observe(this.contentEl);

            elWindow.requestAnimationFrame(() => {
                resizeObserverStarted = true;
            });
        }

        this.mutationObserver = new elWindow.MutationObserver(() => {
            elWindow.requestAnimationFrame(() => {
                this.recalculate();
            });
        });

        this.mutationObserver.observe(this.contentEl, {
            childList: true,
            subtree: true,
            characterData: true
        });
    }

    onPointerEvent = (e) => {
        if (
            !this.axis.x.track.el ||
            !this.axis.y.track.el ||
            !this.axis.x.scrollbar.el ||
            !this.axis.y.scrollbar.el
        ) {
            return;
        }

        let isWithinTrackXBounds; let isWithinTrackYBounds;

        this.axis.x.track.rect = this.axis?.x?.track?.el?.getBoundingClientRect();
        this.axis.y.track.rect = this.axis?.y?.track?.el?.getBoundingClientRect();

        if (this.axis.x.isOverflowing || this.axis.x.forceVisible) {
            isWithinTrackXBounds = this.isWithinBounds(this.axis.x.track.rect);
        }

        if (this.axis.y.isOverflowing || this.axis.y.forceVisible) {
            isWithinTrackYBounds = this.isWithinBounds(this.axis.y.track.rect);
        }

        // If any pointer event is called on the scrollbar
        if (isWithinTrackXBounds || isWithinTrackYBounds) {
            // Prevent event leaking
            e.stopPropagation();

            if (e.type === 'pointerdown' && e.pointerType !== 'touch') {
                if (isWithinTrackXBounds) {
                    this.axis.x.scrollbar.rect = this.axis.x.scrollbar.el.getBoundingClientRect();

                    if (this.isWithinBounds(this.axis.x.scrollbar.rect)) {
                        this.onDragStart(e, 'x');
                    } else {
                        this.mouseDown = true;
                        this.selectedAxis = 'x';
                        this.onTrackClick(e, 'x');
                    }
                }

                if (isWithinTrackYBounds) {
                    this.axis.y.scrollbar.rect = this.axis.y.scrollbar.el.getBoundingClientRect();

                    if (this.isWithinBounds(this.axis.y.scrollbar.rect)) {
                        this.onDragStart(e, 'y');
                    } else {
                        this.mouseDown = true;
                        this.selectedAxis = 'y';
                        this.onTrackClick(e, 'y');
                    }
                }
            }
        }
    };

    onTrackClick(e, axis = 'y') {
        const currentAxis = this.axis[axis];
        if (this.scrolling) {
            return;
        }

        e.preventDefault();

        const elWindow = this.getElementWindow(this.el);
        this.axis[axis].scrollbar.rect = currentAxis.scrollbar.el.getBoundingClientRect();
        const scrollbar = this.axis[axis].scrollbar;
        const scrollbarOffset = scrollbar.rect?.[this.axis[axis].offsetAttr] ?? 0;
        const hostSize = parseInt(this.elStyles?.[this.axis[axis].sizeAttr] ?? '0px', 10);
        let scrolled = this.contentWrapperEl[this.axis[axis].scrollOffsetAttr];
        const t = axis === 'y' ? this.mouseY - scrollbarOffset : this.mouseX - scrollbarOffset;
        const dir = t < 0 ? -1 : 1;
        let scrollSize = dir === -1 ? scrolled - hostSize : scrolled + hostSize;
        const scrollTrack = this.axis[axis].track;
        const scrollRect = scrollTrack.el.getBoundingClientRect();
        const scrollSizeAttribute = this.axis[axis].scrollSizeAttr;

        const scrollTo = (_this) => {
            this.scrolling = true;
            const scrollPoint = this[`mouse${axis.toUpperCase()}`] - scrollRect[this.axis[axis].offsetAttr];
            const relativePos = (scrollPoint / scrollTrack.el[this.axis[axis].offsetSizeAttr]) * this.contentEl[scrollSizeAttribute];
            if (dir === -1) {
                if (scrolled < scrollSize) {
                    this.scrolling = false;
                    if (!this.mouseDown || scrolled < relativePos) {
                        return;
                    }
                    scrollSize = scrolled - hostSize;
                    setTimeout(() => {
                        if (this.mouseDown) scrollTo(this);
                    }, this.speed);
                    return;
                }
                scrolled -= this.options.clickOnTrackSpeed;
                this.contentWrapperEl.scrollTo((this.contentWrapper = {}, this.contentWrapper[this.axis[axis].offsetAttr] = scrolled, this.contentWrapper));
                elWindow.requestAnimationFrame(() => {
                    scrollTo(this);
                });
            } else {
                if (scrolled > scrollSize || scrolled > relativePos) {
                    this.scrolling = false;
                    if (!this.mouseDown) {
                        return;
                    }
                    scrollSize = scrolled + hostSize;
                    setTimeout(() => {
                        if (this.mouseDown) scrollTo(this);
                    }, this.speed);
                    return;
                }
                scrolled += this.options.clickOnTrackSpeed;
                this.contentWrapperEl.scrollTo((this.contentWrapper2 = {}, this.contentWrapper2[this.axis[axis].offsetAttr] = scrolled, this.contentWrapper2));
                elWindow.requestAnimationFrame(() => {
                    scrollTo(this);
                });
            }
        };

        scrollTo(this);
    }

    getElementWindow = (element) => {
        if (!element || !element.ownerDocument || !element.ownerDocument.defaultView) {
            return window;
        }
        return element.ownerDocument.defaultView;
    };
}

export { SimplebarOverride };
