import { inject } from 'aurelia-framework';
import { SessionService } from 'services/session-service';
import { CurrencyService } from 'services/currency-service';
import { Helper } from 'resources/extensions/helper';
import numeral from 'numeral';

@inject(SessionService, CurrencyService, Helper)
export class CurrencyFormatValueConverter {
    signals = ['currency-changed'];
    currentCurrency;

    constructor(sessionService, currencyService, helper) {
        this.sessionService = sessionService;
        this.currencyService = currencyService;
        this.helper = helper;
    }

    async toView(value, format, overrideCurrency, avoidConversion = false, isBalance = false, forceRate = null) {
        if (value === '-') return value;

        if (!format) {
            format = '(0,0.00)';
        }

        if (typeof value !== 'number') value = Number(value);
        const gameCurrency = overrideCurrency && typeof overrideCurrency === 'object';

        let currentCurrency = gameCurrency ? overrideCurrency?.currencyProductSymbol ?? 'G' : overrideCurrency;
        currentCurrency = await this.checkForCurrentCurrency(currentCurrency);

        let returnString;
        if (!value && value !== 0) {
            returnString = 0.00;
        } else if (value < 0) {
            returnString = numeral(value).format(format).replace('(', '').replace(')', '');
        } else if (avoidConversion) {
            returnString = numeral(value).format(format);
        } else {
            const convertedPrice = await this.getConvertedPrice(value, gameCurrency, overrideCurrency, currentCurrency, isBalance, false, forceRate);
            returnString = numeral(convertedPrice).format(format);
        }
        const prefix = this.getCurrencyPrefix(currentCurrency, overrideCurrency, gameCurrency);
        const minus = value?.toFixed(2) < 0 ? '-' : '';
        if (gameCurrency && overrideCurrency) {
            returnString = minus + returnString + prefix;
        } else if (prefix) {
            returnString = minus + prefix + returnString;
        }
        return returnString;
    }

    async getConvertedPrice(value, gameCurrency, overrideCurrency, currentCurrency, isBalance, forceNumberIfFailed = false, forceRate = null) {
        currentCurrency = await this.checkForCurrentCurrency(currentCurrency);
        let rate;
        let attempts = 0;

        if (forceRate) rate = forceRate;
        // eslint-disable-next-line curly
        else while (attempts < 5 && !rate) {
            rate = gameCurrency && overrideCurrency ? overrideCurrency?.price : this.currencyService?.getStoredCurrencyRates(currentCurrency, isBalance ? false : true);
            if (!rate) {
                attempts++;
                if (attempts < 5) await new Promise(resolve => setTimeout(resolve, 100));
            }
        }

        if (!rate) {
            return forceNumberIfFailed ? 0 : 'CURRENCY-ERROR';
        }

        const total = this.evenRound(value, 5);
        return gameCurrency && overrideCurrency ? (total / rate) * (overrideCurrency?.currencyPercentage ? (1 + (overrideCurrency?.currencyPercentage / 100)) : 1.25) : total * rate;
    }

    getUsdValue(value, currency) {
        const rate = this.currencyService.getStoredCurrencyRates(currency);
        return value / (rate ?? 1);
    }

    getCurrencyPrefix(currentCurrency, overrideCurrency, gameCurrency) {
        const activeCurrencies = this.currencyService.getStoredActiveCurrenciesByWebsite();
        return gameCurrency && overrideCurrency ? currentCurrency : activeCurrencies?.find(x => x.code === currentCurrency)?.symbol || '$';
    }

    checkForCurrentCurrency = async(currency) => !this.helper.isEmpty(currency) ? currency : await this.sessionService.getCurrency();

    evenRound(num, decimalPlaces) {
        const d = decimalPlaces || 0;
        const m = Math.pow(10, d);
        const n = +(d ? num * m : num).toFixed(8); // Avoid rounding errors
        const i = Math.floor(n); const f = n - i;
        const e = 1e-8; // Allow for rounding errors in f
        const r = (f > 0.5 - e && f < 0.5 + e) ?
            ((i % 2 === 0) ? i : i + 1) : Math.round(n);
        return d ? r / m : r;
    }
}
