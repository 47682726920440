export class TooltipOverride {
    toView(tooltipElement) {
        const otherTooltips = document.querySelectorAll('.mdc-tooltip--shown');
        otherTooltips.forEach(x => x.classList.remove('mdc-tooltip--shown', 'mdc-tooltip--showing-transition', 'mdc-tooltip--showing'));

        if (tooltipElement.tooltip.classList.contains('mdc-tooltip--hide')) {
            tooltipElement.tooltip.classList.remove('mdc-tooltip--hide', 'mdc-tooltip--hide-transition');
            tooltipElement.tooltip.classList.add('mdc-tooltip--shown', 'mdc-tooltip--showing-transition', 'mdc-tooltip--showing');
            tooltipElement.tooltip.style.top = `${tooltipElement.root.getBoundingClientRect().top + 30}px`;
        } else {
            tooltipElement.tooltip.classList.add('mdc-tooltip--hide', 'mdc-tooltip--hide-transition');
            tooltipElement.tooltip.classList.remove('mdc-tooltip--shown', 'mdc-tooltip--showing-transition', 'mdc-tooltip--showing');
        }
    }
}
