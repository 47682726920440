import { inject } from 'aurelia-framework';
import { ApiService } from './api-service';
import { Helper } from 'resources/extensions/helper';

@inject(ApiService, Helper)
export class LanguageWebsiteService {
    path = 'LanguageWebsite';
    languages;

    constructor(api, helper) {
        this.api = api;
        this.helper = helper;
    }

    async getByWebsite() {
        return this.helper.handlePendingRequest(this, async() => {
            if (this.languages) return this.languages;
            this.languages = await this.api.doGet(`${this.path}/ByWebsite?websiteShortCode=CG`);
            return this.languages.sort((a, b) => {
                return ((b.position !== null) - (a.position !== null) || a.position - b.position) || a.language?.name?.localeCompare(b.language.name);
            });
        });
    }
}
