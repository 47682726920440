import { inject } from 'aurelia-framework';
import { ApiService } from './api-service';
import { DateOnlyValueConverter } from 'resources/value-converters/date-only';
import { ProductService } from './product-service';
import { Helper } from 'resources/extensions/helper';
import moment from 'moment';

@inject(ApiService, ProductService, DateOnlyValueConverter, Helper)
export class SubscriptionService {
    path = 'Subscription';
    subscriptions;

    constructor(api, productService, dateOnlyValueConverter, helper) {
        this.api = api;
        this.productService = productService;
        this.dateOnlyValueConverter = dateOnlyValueConverter;
        this.helper = helper;
    }

    getRoute = (route) => `${this.path}${route ? '/' : ''}${route}`;

    get = async(route) => await this.api.doGet(this.getRoute(route));

    put = async(route, request) => await this.api.doPut(this.getRoute(route), request);

    getParams = async(route, params) => await this.get(this.helper.toParams(route, params));

    getByUserId = async(userId) => await this.getParams('GetByUserId', { userId });

    getByName = async(name) => await this.getParams('GetByName', { name });

    getChicksVIP = async() => await this.getByName('Chicks VIP');

    getById = async(id) => await this.get(id);

    getProductsBySubscriptionName = async(name = 'Chicks VIP') => await this.productService.getProductsWithFilter(`${name}`);

    getActiveSubscription = (user, checkWithCancel) => user?.subscriptions?.find(x => {
        const isAfterRenewalDate = this.dateOnlyValueConverter.toView(x.renewalDate) > this.dateOnlyValueConverter.toView(new Date());
        const isSubscribed = x.isSubscribed;

        return checkWithCancel
            ? isAfterRenewalDate && isSubscribed
            : isAfterRenewalDate || isSubscribed;
    });

    hasChicksVipFreeTrial = async(user) => {
        if (!user) {
            return true;
        }
        return await this.get('CheckIfUserHasSubscriptionFreeTrial');
    };

    hasSubscription = (user, checkWithCancel) => Boolean(this.getActiveSubscription(user, checkWithCancel));

    getAvailableChicksVipProducts = async(user) => {
        if (this.hasSubscription(user)) {
            return;
        }
        let chicksVipProducts = await this.getProductsBySubscriptionName();
        chicksVipProducts = chicksVipProducts.filter(x => !x.name.includes('Renewal'));

        return chicksVipProducts.map(x => {
            x.productId = x.id;
            x.hasFreeTrial = user ? user.hasFreeTrial : true;
            if (x.hasFreeTrial) {
                x.priceAfterTrial = x.price;
                x.price = 0;
            }
            if (x.name.includes('Monthly') || x.hasFreeTrial) {
                x.validUntil = moment().add(30, 'days');
            } else {
                x.validUntil = moment().add(1, 'year');
            }
            x.quantity = x.tempQuantity = 1;
            return x;
        });
    };

    unsubscribeUser = async(data) =>
        await this.api.doPost(`${this.path}/Unsubscribe`, {
            subscriptionId: data.subscriptionId,
            userId: data.userId,
            reasons: data.reasons,
            unsubscribeOtherReason: data.unsubscribeOtherReason
        });

    updateSubscriber = async(data, renewal = false) => {
        const request = {
            subscriptionId: data.subscriptionId,
            userId: data.userId,
            paymentPlan: data.paymentPlan,
            mainPaymentMethodId: data.mainPaymentMethodId,
            backupPaymentMethodId: data.backupPaymentMethodId,
            mainCardLastDigits: data.mainCardLastDigits,
            backupCardLastDigits: data.backupCardLastDigits
        };
        if (renewal) return this.api.doPost(`${this.path}/Subscribe`, request);
        return await this.api.doPut(`${this.path}/UpdateSubscriber`, request);
    };

    getAdCounter = async(subscriptionId, userId) => await this.getParams('GetSubscriptionAdCounter', { subscriptionId, userId });

    updateAdCounter = async(subscriptionId) => await this.put('UpdateSubscriptionAdCounter', subscriptionId);

    async getAll() {
        if (!this.subscriptions) {
            this.subscriptions = await this.get();
        }
        return this.subscriptions;
    }

    calculateVIPCashback(product, cashback, price, category = null) {
        const productField = category ? product[category] : product;

        if (productField?.productCategory || productField?.productCategoryName) {
            const percent = ['Accounts', 'Services'].includes(productField?.productCategory?.name || productField?.productCategoryName) ? 0.1 : 0.03;
            const productPrice = Math.abs(product[price]);
            cashback += (productPrice - (product?.deliveryFee ?? 0)) * percent;
        }

        return cashback;
    }

    async totalCashbackEarned() {
        return await this.get('TotalCashbackEarned');
    }
}
