import { inject } from 'aurelia-framework';
import { baseUrl, debug, fingerprintApiKey, fingerprintDomainUrl, environmentName } from 'environment';
import { DomainsOverride } from 'resources/domains_override';
import { emailDisallowConRegExr } from 'resources/constants';
import numeral from 'numeral';
import moment from 'moment';
import sanitizeHtml from 'sanitize-html';
import emailMisspelled from 'email-misspelled';
import { EventAggregator } from 'aurelia-event-aggregator';
import { RegExpMatcher, englishDataset, englishRecommendedTransformers } from 'obscenity';
import * as constants from 'resources/constants';
import FingerprintJS, { defaultEndpoint, defaultScriptUrlPattern } from '@fingerprintjs/fingerprintjs-pro';
import { ToastService } from 'services/toast-service';
import { OrderTransactionType, ProductCategoryType, ToastType } from 'resources/helpers/enums';

@inject(EventAggregator, ToastService)
export class Helper {
    constructor(eventAggregator, toastService) {
        this.eventAggregator = eventAggregator;
        this.toastService = toastService;
        this.getResolutions(this);
        this.matcher = new RegExpMatcher({
            ...englishDataset.build(),
            ...englishRecommendedTransformers
        });
        this.pendingRequests = new Map();
    }

    signInOptions = ['login', 'sign_up', 'CompleteRegistration', 'SignUp'];
    fingerprint;
    loadingState = [];

    serviceState = {};
    // ----- General Functionality -----

    blobToFile(theBlob, fileName) {
        const b = theBlob;
        b.lastModifiedDate = new Date();
        b.name = fileName;
        return theBlob;
    }

    phoneFormat(value, code) {
        if (!value) {
            return '-';
        }

        const aux = value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);

        const part1 = aux[1] || '';
        const part2 = aux[2] || '';
        const part3 = aux[3] || '';

        return `+${code ?? ''}${part1 ? ` ${part1}` : ''}${part2 ? `-${part2}` : ''}${part3 ? `-${part3}` : ''}`;
    }

    redirectToSignIn(navigationInstruction, router) {
        const queryString = navigationInstruction?.queryString;
        const url = `/sign-in?redirect_url=${window.location.pathname.substring(1)}${queryString ? `?${encodeURIComponent(queryString)}` : ''}`;
        router?.navigate(url);
        return url;
    }

    // Checks specific ErrorReason to show specific notification, and trigger a callback, from list of error handlers.
    requestErrorHandler = (response, reasonTypes) => {
        if (!response || !response?.message || !response?.reason || !reasonTypes?.length) return response;
        const reasonType = reasonTypes.find(x => x.reason === response.reason);
        if (!reasonType) return response;
        const type = reasonType?.type ?? ToastType.ERROR;
        const title = reasonType.title ?? this.toPascal(type);
        this.toastService.showToast(type, response.message, reasonType.title ?? title);
        reasonType?.callback?.();
        return reasonType.defaultValue ?? response;
    };

    handleToastErrorWithKeys = (responseKeys, response) => {
        this.toastService.showToast(ToastType.ERROR, responseKeys[response.responseCode] ?? response.description);
    };

    validateCondition(condition, message, onFail = null, type = ToastType.ERROR, title) {
        if (condition) return true;
        this.toastService.showToast(type, message, title);
        onFail?.();
        return false;
    }

    isImage(contentType) {
        const result = ['text/plain', 'image/jpg', 'image/jpeg', 'image/pjpeg', 'image/gif', 'image/x-png', 'image/png', 'image/jfif']
            .includes(contentType);

        return result;
    }

    isVideo(contentType) {
        const result = ['application/octet-stream', 'video/quicktime', 'video/mp4', 'video/mov', 'video/wmw', 'video/flv', 'video/avi', 'video/avchd', 'video/webm', 'video/mkv']
            .includes(contentType);

        return result;
    }

    // ----- Tooltip----
    tooltipGlobal = (width) => {
        const tooltipEl = document.querySelector('.mdc-tooltip.mdc-tooltip--shown');
        const tooltipContent = tooltipEl?.querySelectorAll('.mdc-tooltip__surface.mdc-tooltip__surface-animation');
        const style = tooltipEl?.getAttribute('style');
        if (style && tooltipEl?.clientHeight > 80 && width > this.phone) {
            let left = parseFloat(style?.substring(style.indexOf('left:') + 6, style.indexOf('px;', style.indexOf('left:'))));
            const maxWidth = 270;
            left = left - ((maxWidth - tooltipEl.clientWidth) / 2);
            tooltipEl.setAttribute('style', `${style.substring(0, style.indexOf('left:'))}left: ${left}px; min-width: ${maxWidth}px;`);
            tooltipContent.forEach(element => element.setAttribute('style', `${style.substring(0, style.indexOf('left:'))}left: ${left}px; min-width: ${maxWidth}px;`));
        }
    };

    setETATooltip(tooltipIdentifier) {
        this.tooltipIdentifier = tooltipIdentifier;
        const classes = ['mdc-tooltip', 'arrow-position-left-offset', 'arrow-position-below'];
        tooltipIdentifier?.tooltip.classList.add(...classes);
        const tooltip = tooltipIdentifier?.tooltip.querySelector('.mdc-tooltip__surface');
        tooltip.style.marginLeft = '-15px';
        tooltip.style.textAlign = 'left';
    }

    ETATooltipIconHandler(product) {
        const category = product?.productCategory?.name ?? product?.productCategoryName;
        const validCategories = ['Skins', 'Accounts', 'Items', 'Currency', 'Gift Cards'];
        if (!validCategories.includes(category)) return '';
        const icon = (category === 'Accounts' && !product?.deliveryPeriodText) || product?.deliveryPeriodText === 'Instant' ? 'bolt' : 'green_clock';
        return `/icons/${icon}.svg`;
    }

    setAverageDeliveryTime(product, isFull) {
        const ETACopy = isFull ? 'Expect delivery of this product ' : '';
        const category = product?.productCategory?.name ?? product?.productCategoryName;
        const deliveryPeriod = product?.deliveryTime === 1 ? product?.deliveryPeriodText.slice(0, -1) : product?.deliveryPeriodText;

        if (!category || category === 'Services') {
            return;
        } if (deliveryPeriod === 'Instant' || (category === 'Accounts' && deliveryPeriod === '')) {
            return `${ETACopy} ${isFull ? 'instantly' : 'Instant'}`;
        }

        const deliveryTime = Math.ceil([product.deliveryTime, product.averageDeliveryTime, 10].find(x => x > 0));
        const deliveryTimeData = moment.duration(deliveryTime, 'minutes');
        const checks = ['days', 'hours', 'minutes'];

        let found = checks.find(x => deliveryTimeData._data[x] > 0);
        const foundResult = deliveryTimeData._data[found];

        if (foundResult === 1) {
            found = found.slice(0, -1);
        }

        const result = product.deliveryTime > 0
            ? `${product.deliveryTime} ${isFull ? deliveryPeriod.toLowerCase() : this.toPascal(deliveryPeriod)}`
            : `${foundResult} ${isFull ? found : this.toPascal(found)}`;

        return `${ETACopy ? `${ETACopy} within` : ''} ${result}`;
    }

    handleGtagEvent = (eventName, itemObject, currency, price, couponCode, method, consentStatus = null) => {
        this.removeGtagEvent();
        if (!itemObject && !price && !this.signInOptions.includes(eventName) && consentStatus === null) return;
        const script = document.createElement('script');
        script.setAttribute('id', 'ga4-event');
        script.setAttribute('type', 'text/javascript');
        if (itemObject && !Array.isArray(itemObject)) itemObject = [itemObject];
        script.innerHTML = `window.dataLayer = window.dataLayer || [];
                            function gtag(){dataLayer.push(arguments);}
                            gtag('js', new Date());
    
                            gtag('config', 'G-TPVD094J0Y');
                            
                            gtag('${consentStatus !== null ? 'consent' : 'event'}', '${eventName}'`;

        if (!this.signInOptions.includes(eventName) || (this.signInOptions.includes(eventName) && (debug() || method)) || consentStatus) script.innerHTML += ', {';

        price = Math.abs(price);
        const formattedPrice = itemObject && !Array.isArray(itemObject) && itemObject.productCategory?.name === 'Currency' ? numeral(price).format('0.00[000]') : numeral(price).format('0.00');

        if (price) {
            script.innerHTML += `'currency': "${currency}",
                                 'value': ${formattedPrice}`;
        }

        if (debug()) {
            if (price) script.innerHTML += ', ';
            script.innerHTML += '\'debug_mode\': true';
        }

        if (couponCode) {
            if (price || debug()) script.innerHTML += ', ';
            script.innerHTML += `'coupon': "${couponCode}"`;
        }

        if (itemObject?.length) {
            if (price || debug() || couponCode) script.innerHTML += ', ';
            script.innerHTML += '\'items\': [';

            for (const [index, item] of Array.from(itemObject).entries()) {
                script.innerHTML += `{
                                        'item_id': "${item.id}",
                                        'item_name': "${item.name}",\n`;

                if (itemObject.position) script.innerHTML += `'index': ${item.position},`;
                if (itemObject.internalId) script.innerHTML += `'item_variant': "${item.internalId}",`;

                script.innerHTML += '\'affiliation\': "Chicks Gold Inc.",\n';

                [item.productCategory?.name, item.game?.name].filter(x => x).forEach((x, i) => script.innerHTML += `'item_category${i ? i + 1 : ''}': "${x}",\n`);

                script.innerHTML += `'price': ${formattedPrice},
                                    'quantity': ${eventName === 'view_item' ? 1 : parseInt(item.quantity?.toString()?.replaceAll(',', '') ?? item.tempQuantity?.toString()?.replaceAll(',', ''))}
                                    }`;
                if (index < itemObject.length - 1) script.innerHTML += ',';
            }

            script.innerHTML += ']';
        }

        if (method) {
            if (debug()) script.innerHTML += ', ';
            script.innerHTML += `'method': "${method}"`;
        }

        if (consentStatus !== null) {
            script.innerHTML += `${debug() ? ',' : ''}
             'ad_storage': '${consentStatus ? 'granted' : 'denied'}',
             'ad_user_data': '${consentStatus ? 'granted' : 'denied'}',
             'ad_personalization': '${consentStatus ? 'granted' : 'denied'}',
             'analytics_storage': '${consentStatus ? 'granted' : 'denied'}'`;
        }

        if (debug() || !this.signInOptions.includes(eventName) || (this.signInOptions.includes(eventName) && method) || consentStatus !== null) script.innerHTML += '}';
        script.innerHTML += ');';

        document.body.appendChild(script);
    };

    removeGtagEvent = () => document.getElementById('ga4-event')?.remove();

    handleFacebookPixelEvent = (eventName, itemObject, currency, price) => {
        if (debug()) return;
        this.removeFacebookPixelEvent();
        const script = document.createElement('script');
        script.setAttribute('id', 'fb-pixel-event');
        script.setAttribute('type', 'text/javascript');
        if (itemObject && !Array.isArray(itemObject)) itemObject = [itemObject];
        script.innerHTML = `fbq('track', '${eventName}'`;

        if (!this.signInOptions.includes(eventName)) {
            script.innerHTML += ', {';

            if (currency) {
                script.innerHTML += `currency: "${currency}"`;
            }

            if (price) {
                script.innerHTML += `, value: ${price}`;
            }

            if (itemObject?.length) {
                script.innerHTML += `, content_ids: [${itemObject.map(x => x.id)}]`;

                script.innerHTML += ', contents: [';

                for (const [index, item] of Array.from(itemObject).entries()) {
                    script.innerHTML += `{
                                            id: "${item.id}",
                                            quantity: ${parseInt(item.quantity?.toString()?.replaceAll(',', '') ?? item.tempQuantity?.toString()?.replaceAll(',', ''))}
                                        }`;
                    if (index < itemObject.length - 1) script.innerHTML += ',';
                }

                script.innerHTML += ']';

                if (eventName === 'InitiateCheckout') {
                    script.innerHTML += `, num_items: ${itemObject.length}`;
                }

                if (eventName === 'AddToCart') {
                    script.innerHTML += ', content_type: "product"';
                }
            }

            script.innerHTML += '}';
        }

        script.innerHTML += ');';

        document.body.appendChild(script);
    };

    removeFacebookPixelEvent = () => document.getElementById('fb-pixel-event')?.remove();

    handleRedditEvent = (eventName, itemObject, currency, price) => {
        if (debug()) return;
        this.removeRedditEvent();
        const script = document.createElement('script');
        script.setAttribute('id', 'reddit-event');
        script.setAttribute('type', 'text/javascript');
        if (itemObject && !Array.isArray(itemObject)) itemObject = [itemObject];
        script.innerHTML = `rdt('track', '${eventName}'`;

        if (!this.signInOptions.includes(eventName)) {
            script.innerHTML += ', {';

            if (currency && price && eventName !== 'ViewContent') {
                script.innerHTML += `currency: "${currency}"`;
            }

            if (price && currency && eventName !== 'ViewContent') {
                script.innerHTML += `, value: ${price}`;
            }

            if (itemObject?.length) {
                script.innerHTML += `${currency && price && eventName !== 'ViewContent' ? ', ' : ''}products: [`;

                for (const [index, item] of Array.from(itemObject).entries()) {
                    script.innerHTML += `{
                                            id: "${item.id}",
                                            category: "Product",
                                            name: "${item.serviceFullName ?? item.name}"
                                        }`;
                    if (index < itemObject.length - 1) script.innerHTML += ',';
                }

                script.innerHTML += ']';

                if (eventName === 'AddToCart') {
                    script.innerHTML += `, itemCount: ${itemObject.length}`;
                }
            }

            script.innerHTML += '}';
        }

        script.innerHTML += ');';

        document.body.appendChild(script);
    };

    removeRedditEvent = () => document.getElementById('reddit-event')?.remove();

    addPrerenderMetaTagForRedirect = (url) => {
        const metaPrerenderStatusCode = document.createElement('meta');
        metaPrerenderStatusCode.setAttribute('id', 'prerender-status-code');
        metaPrerenderStatusCode.setAttribute('name', 'prerender-status-code');
        metaPrerenderStatusCode.setAttribute('content', '301');

        const metaPrerenderHeader = document.createElement('meta');
        metaPrerenderHeader.setAttribute('id', 'prerender-header');
        metaPrerenderHeader.setAttribute('content', `Location: ${baseUrl().slice(0, -1)}${url}`);

        document.head.appendChild(metaPrerenderStatusCode);
        document.head.appendChild(metaPrerenderHeader);
    };

    removePrerenderMetaTagForRedirect = () => {
        document.getElementById('prerender-header')?.remove();
        document.getElementById('prerender-status-code')?.remove();
    };

    convertToUrlName = (name) => {
        const urlNameResult = [];
        name?.replace(/[\s/]/g, '-').replace(/[^a-zA-Z0-9-_]/g, '').toLowerCase().split('-').forEach((a) => { if (a !== '') urlNameResult.push(a.replace('-', '')); });
        return urlNameResult.join('-');
    };

    handlePrerender404 = (router) => {
        const prerenderMeta = document.getElementById('prerender-404-status-code');
        if (router.currentInstruction.config.name === '404' && !prerenderMeta) {
            const prerenderMetaTag = document.createElement('meta');
            prerenderMetaTag.setAttribute('id', 'prerender-404-status-code');
            prerenderMetaTag.setAttribute('name', 'prerender-status-code');
            prerenderMetaTag.setAttribute('content', '404');
            document.head.appendChild(prerenderMetaTag);
        }
    };

    // Input: Path of the route, Object containing the keys and values of the parameters.
    // Output: Parametized string.
    // Result: myPath?var1=val1&var2=val2
    toParams = (path, obj) => {
        if (!obj || this.isObjectEmpty(obj)) return path;
        return path + '?' + Object.keys(obj)
            .filter(x => obj[x] === 0 || obj[x])
            .map(x => {
                if (Array.isArray(obj[x])) {
                    return this.fromArrayToParams(x, obj[x], true);
                }
                return `${x}=${encodeURIComponent(obj[x])}`;
            })
            .join('&');
    };

    fromArrayToParams = (parameter, array, fromParams = false) => (fromParams ? '' : '?') + array.map(x => `${parameter}=${encodeURIComponent(x)}`).join('&');

    // ----- Array Functionality ----

    // Checks if Array or String contains values specified in includes, but also does not contain values specified in excludes.
    // Returns: Boolean defining if it matches.
    includesWithout(arr, includes, excludes, explicit = false) {
        const func = explicit ? 'every' : 'some';
        return this.#includesBy(arr, includes, func) && this.excludeAll(arr, excludes);
    }

    #includesBy = (arr, values, func, name = 'includes') => values[func](x => arr?.[name](x));

    includesAll = (arr, values, name) => this.#includesBy(arr, values, 'every', name);

    includesSome = (arr, values, name) => this.#includesBy(arr, values, 'some', name);

    excludeAll = (arr, values, name = 'includes') => values?.every(x => !arr?.[name](x));

    // Separates array in two arrays depending on a condition and optionally run a custom map function before pushing the elements.
    splitByCondition = (arr, func, mapFunc) => {
        return arr.reduce((result, element) => {
            result[func(element) ? 0 : 1].push(mapFunc ? mapFunc(element) : element);
            return result;
        },
        [[], []]);
    };

    getClampedElement = (arr, index) => arr[this.clamp(index, arr.length - 1)];

    separateByChunks(array, chunks) {
        const result = [];
        for (let i = 0; i < array?.length; i += chunks) {
            result.push(array.slice(i, i + chunks));
        }
        return result;
    }

    selectElementLoop = (arr, index) => arr[this.loopInRange(index, arr.length - 1)];

    filterOutByArray = (array, arrayToFilter) => array?.filter(x => arrayToFilter.some(y => x.paymentMethod ? x.paymentMethod.reference.includes(y) : x.includes(y)));

    insertAt = (array, index, object) => array.splice(index, 0, object);

    // Returns an array of numbers by the given amount
    range = (amount, startFromOne = false, excludeNumber) => {
        let keys = [...Array(amount).keys()];
        keys = startFromOne ? keys.map(i => i + 1) : keys;
        if (typeof excludeNumber === 'number') excludeNumber = [excludeNumber];
        if (excludeNumber) return keys.filter(num => !excludeNumber.includes(num));
        return keys;
    };

    //Compare two arrays of objects if they're equal
    // Returns: Boolean value
    checkIfTwoArraysOfObjectsEqual = (arr1, arr2) => {
        if (!arr2.length) return;
        if (arr1?.length !== arr2?.length) return false;
        return arr1.every((item, index) => JSON.stringify(item) === JSON.stringify(arr2[index]));
    };

    checkMultipleObjectsIfHasExistingValue = (array, keysToSearch, propertyToCheck, propertyToValidate) => {
        if (!array.length) return;
        const clonedArray = array.map(x => Object.assign({}, x));
        const filteredObjects = clonedArray.filter(obj => keysToSearch.includes(obj[propertyToCheck]));
        return filteredObjects.length && filteredObjects.every(x => x[propertyToValidate]);
    };

    /**
     * Updates the total price and unit price of a single product
     * @param {OrderProduct[]} array
     * @param {Object} fields
     * @param {string} idField
     */
    updateProductPrices(array, fields, idField = 'productId') {
        const index = array.findIndex(element => element[idField] === fields.id);

        if (index === -1) return;

        const element = {
            ...array[index],
            totalPrice: fields.totalPrice,
            price: fields.price
        };
        array.splice(index, 1, element);
    }

    // ------ Numeric Functionality -----

    validateNumber(num) {
        if (typeof num === 'string') num = +num;
        if (isNaN(num) || isNaN(parseFloat(num))) return null;
        return num;
    }

    // Keeps numeric value between a range
    clamp = (value, max, min = 0) => Math.min(Math.max(value, min), max);

    //  Loops through numeric range
    loopInRange(number, max, min = 0) {
        const rangeSize = max - min + 1;

        if (number < min) {
            const offset = (min - number) % rangeSize;
            return offset !== 0 ? max - (Math.abs(offset) % rangeSize) + 1 : min;
        }
        return (number - min) % rangeSize + min;
    }

    isInRange = (amount, min, max) => {
        if (min > max) [max, min] = [min, max];
        return amount >= min && amount <= max;
    };

    // ----- Date/Time Functionality -----

    addDays = (date, days) => date.setDate(date.getDate() + days);

    dateFormat(value, format) {
        if (!value) {
            return;
        }

        if (!format) {
            format = 'MMMM Do YYYY, h:mmA';
        }

        if (format === 'calendar') {
            return moment.utc(value).local().calendar({
                sameDay: '[Today at] hh:mm A',
                nextDay: '[Tomorrow at] hh:mm A',
                nextWeek: 'dddd',
                lastDay: '[Yesterday at] hh:mm A',
                lastWeek: 'MM/DD/YYYY',
                sameElse: 'MM/DD/YYYY'
            });
        } else if (format === 'calendarDraft') {
            return moment.utc(value).calendar({
                sameDay: '[Today at] hh:mm A',
                nextDay: '[Tomorrow at] hh:mm A',
                nextWeek: 'dddd',
                lastDay: '[Yesterday at] hh:mm A',
                lastWeek: 'MM/DD/YYYY',
                sameElse: 'MM/DD/YYYY'
            });
        } else if (format === 'calendarPrice') {
            if (moment.utc(value).local().isSame(moment(), 'day')) {
                moment.updateLocale('en', {
                    relativeTime: {
                        h: '1 hour'
                    }
                });
                return moment.utc(value).local().fromNow();
            } else {
                return moment.utc(value).local().calendar({
                    sameDay: '[Today at] hh:mm A',
                    lastDay: '[Yesterday at] hh:mm A',
                    lastWeek: 'MM/DD/YYYY',
                    sameElse: 'MM/DD/YYYY'
                });
            }
        } else if (format === 'dateFromNow') {
            moment.updateLocale('en', {
                relativeTime: {
                    h: '1 hour'
                }
            });
            return moment.utc(value).local().fromNow();
        } else if (format === 'draftFromNow') {
            moment.updateLocale('en', {
                relativeTime: {
                    h: '1 hour'
                }
            });
            return moment(value).fromNow();
        } else if (format === 'timeless') {
            const date = moment(value);
            return date.format('MMMM YYYY');
        } else {
            return moment.utc(value).local().format(format);
        }
    }

    // ----- String Functionality -----

    removeSelectorSymbol = (selector) => selector.replace(/^[#.:\[]?([a-zA-Z_-][\w-]*).*/, '$1');

    isFile = (str, noRoute = false) => /.*\.[^/\\]+$/.test(str) && (noRoute ? this.excludeAll(str, ['/', '\\']) : true);

    isProfane = (str) => this.matcher.hasMatch(str);

    singleSeparator = (str, separator = ' ') => str?.split(separator)?.filter(x => x)?.join(separator);

    // Turn String into Camel Case
    camelize = (str) => str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());

    toPascal = (str) => this.toCapitalize(this.camelize(str), 'first');

    toPlural = (str) => {
        if (!str) return;
        const lastLetter = str[str.length - 1].toLowerCase();
        if (lastLetter === 's') return str;
        return lastLetter === 'y' ? `${str.slice(0, -1)}ies` : `${str}s`;
    };

    /**
     * @param {string | null} value
     * @param {'first' | null} type
     * @returns {string}
     */
    toCapitalize = (value, type) => {
        if (!value) return '';
        if (type === 'first') {
            return value.charAt(0).toUpperCase() + value.slice(1);
        }
        const sentence = value.split(' ');
        return sentence.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
    };

    replaceDashWithSpace = (value) => value.replaceAll('-', ' ');

    kebabToCamelCase = (str) => str.replace(/-([a-z])/g, (_, letter) => letter.toUpperCase());

    /**
     * Transform a string with a secure format for routing
     * @param {string} string
     * @returns {string} The secure-formatted string to use as a route
     */
    toRoute = (string) => string.toLowerCase().trim().replaceAll(' ', '-');

    // Splits only by the first finding
    splitOnce = (haystack, needle) => {
        const index = haystack.indexOf(needle);
        if (index === -1) return [haystack];
        const i = index + needle.length - 1;
        return [haystack.slice(0, i), haystack.slice(i + 1)];
    };

    // Check if the string is empty
    isEmpty = (str) => (!str?.trim()?.length);

    /**
     * @param {string} value
     * @param {'float' | 'int' | null} type
     * @returns {string | number}
     */
    convertNumberWithoutComma = (value, type = null) => {
        value = value?.toString().replaceAll(',', '');
        switch (type) {
            case 'float':
                return parseFloat(value);
            case 'int':
                return parseInt(value);
            default:
                return value;
        }
    };

    generateRandomString = (length = 8) => Math.random().toString(36).substring(0, length);

    // ----- Regular Expression Functionality -----

    /**
     * Transforms the filename into a secure format
     * @param {string} fileName
     * @returns {string} The formatted filename using secure guidelines
     */
    formatFileName(fileName) {
        const nameWithoutExtension = fileName.replace(/\.[^/.]+$/, '');
        return nameWithoutExtension
            .replace(/\s+/g, '_')
            .replace(/[^\w.-]/g, '');
    }

    /**
     * Gets the extension of a file from its MIME type
     * @param {string} mimeType
     * @returns {string} The extension for the file extracted from the MIME type
     */
    getExtensionFromMimeType(mimeType) {
        return mimeType.replace(/.*\/|\+.*$/g, '');
    }

    // Checks if the elements matches the given regex
    matchAny = (regex, values) => values.some(x => regex.test(x));

    // ----- Object Functionality -----

    objectMap(obj, callback) {
        return Object.keys(obj).reduce((newObj, key) => {
            newObj[key] = callback(obj[key]);
            return newObj;
        }, {});
    }

    static classExtender(...bases) {
        class Bases {
            constructor() {
                bases.forEach(base => Object.assign(this, new base()));
            }
        }
        bases.forEach(base => {
            Object.getOwnPropertyNames(base.prototype)
                .filter(prop => prop !== 'constructor')
                .forEach(prop => Bases.prototype[prop] = base.prototype[prop]);
        });
        return Bases;
    }

    getUserFullName(value, lastName = '') {
        if (!value && !lastName) {
            return '-';
        }

        if (!lastName) {
            lastName = '';
        }

        const fullNameSplit = `${ value } ${ lastName }`.split(' ');
        return fullNameSplit.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
    }

    copyArrayOfObjects = (arr) => {
        const copy = [...arr];
        return copy.map(x => Object.assign({}, x));
    };

    getEnumName(enumVar, value) {
        return value || value === 0 ? Object.keys(enumVar).find(x => enumVar[x] === value) : undefined;
    }

    /*
    This function will recieve an object, and set the specified value to all properties that include any of the specified names, and not modify those that include the
    string that are inside of the exclude array, if it exists.
    */
    setPropertiesByName(obj, names, value, exclude = []) {
        this.getPropertiesByName(obj, names, exclude).forEach(x => obj[x] = value);
    }

    getPropertiesByName(obj, names, exclude = []) {
        return Object.keys(obj)?.filter(x => this.includesWithout(x, names, exclude));
    }

    getVariablesByName(obj, names, exclude = []) {
        return this.getPropertiesByName(obj, names, exclude).map(x => obj[x]);
    }

    copyProperties = (obj, obj2, props) => {
        props?.forEach(x => {
            obj[x] = obj2[x];
        });
    };


    /**
     * Checks whether the object has a value assigned in the specified properties.
     * @param {object} obj The object to validate.
     * @param {string[]} properties The properties to check.
     * @param ignoreFalsy Whether to check specifically by null and undefined, or all falsy values.
     * @returns A boolean describing if the object contains all the properties.
     */
    hasAllProperties = (obj, properties, ignoreFalsy = false) => {
        if (!obj) return false;
        return properties.every(x => ignoreFalsy
            ? obj[x] !== null && obj[x] !== undefined
            : obj[x]
        );
    };

    uniqueByProperty(arr, prop) {
        return arr.reduce((accumulator, current) => {
            if (!accumulator.find((item) => this.propertyByString(item, prop) === this.propertyByString(current, prop))) {
                accumulator.push(current);
            }
            return accumulator;
        }, []);
    }

    propertyByString(value, string) {
        if (!value) return;
        return string.split('.').reduce((obj, prop) => obj?.[prop], value);
    }

    invalidProduct = (product) => !product.imagePath || ['Currency', 'Services'].includes(product.productCategory.name) || product?.game?.shortName === 'SUBSCRIPTION';

    checkEntityStatus(value, fulfilled) {
        if (!value) {
            return;
        }

        const status = value.split(':')[1];

        //Orders & Notifications
        if (value.includes('complete') && (fulfilled === '1' || fulfilled === 'True' || fulfilled === true)) {
            return 'Complete';
        }
        if (this.includesSome(value, ['refund-requested', 'marked-sent', 'partially-refunded', 'refunded', 'rejected', 'created', 'partially-delivered', 'all', 'none', 'cancelled'])) {
            return this.toCapitalize(status?.replace('-', ' '), 'first');
        }
        if (['active', 'closed', 'draft', 'read', 'unread'].includes(value)) {
            return this.toCapitalize(value, 'first');
        }
        return 'Pending';
    }

    // Checks if object is empty
    isObjectEmpty = (obj) => obj ? Object.keys(obj).length === 0 : null;

    handleIfSelectedGameForSortness = (a, b) => ((b.position !== null) - (a.position !== null) || a.position - b.position) || a.id - b.id;

    handleForPriceSortness = (a, b, type) => {
        if (type === 'high to low') return b.salePrice && a.salePrice ? b.salePrice - a.salePrice : b.salePrice ? b.salePrice - a.price : a.salePrice ? b.price - a.salePrice : b.price - a.price;
        return a.salePrice && b.salePrice ? a.salePrice - b.salePrice : a.salePrice ? a.salePrice - b.price : b.salePrice ? a.price - b.salePrice : a.price - b.price;
    };

    checkIfObjectContainsAnyPropertyWithValue = (obj, arrayToExcludeProperties) => {
        if (arrayToExcludeProperties?.length) {
            const clonedObject = Object.assign({}, obj);
            for (const property of arrayToExcludeProperties) {
                delete clonedObject[property];
            }
            return Object.values(clonedObject).some(x => x);
        }
        return Object.values(obj).some(x => x);
    };

    /**
     * This function will compare two objects's properties and declare if they are matching, it can also exclude ohter properties.
     * @param {object} obj First object to compare
     * @param {object} obj2 Second object to compare
     * @param {string[]} properties Array of strings with the properties
     * @param {string[]} exclude Array of strings with properties that should not match
     * @returns {boolean} whether the propeties are shared and/or unshared.
     */
    compareProperties = (obj, obj2, properties, exclude) => {
        const compare = (prop) => this.propertiesEqual(obj[prop], obj2[prop]);
        let result = properties.every(compare);
        if (exclude) {
            result = result && !exclude.some(compare);
        }
        return result;
    };

    // ----- DOM Functionality -------

    applyFocus(element) {
        if (!element || element?.isFocused) return;
        element.focus?.();
    }

    handleMainPageScroll(isOpen = true) {
        const navigationBarStyle = document.getElementById('navigation-container');
        const disableMainPageScroll = document.getElementById('main-page-host');
        if (isOpen) {
            navigationBarStyle.style.zIndex = '0';
            disableMainPageScroll.style.overflow = 'hidden';
        } else {
            navigationBarStyle ? navigationBarStyle.style.zIndex = null : '';
            disableMainPageScroll ? disableMainPageScroll.style.overflow = null : '';
        }
        this.eventAggregator.publish('drawer-closed', { isClosed: false });
    }

    queryMultiple(element, ...selectors) {
        return selectors.map(selector => element.querySelector(selector));
    }

    getElementStyle = (element) => element.currentStyle || window.getComputedStyle(element);

    elementsContainAnyClass = (elements, classes) => {
        return elements.some(x => this.containsAnyClass(x, classes));
    };

    containsAnyClass = (element, classes) => {
        return this.includesSome(element.classList, classes, 'contains');
    };

    addFilterArrow(selector) {
        const selectArrows = this.selectArray(selector, '.mdc-select .mdc-select__dropdown-icon');
        this.setFilterArrow(selectArrows);
    }

    setFilterArrow(elements) {
        const newArrowIcon = '<span class="material-icons global-arrow-icon">arrow_drop_down</span>';
        elements?.map(x => x).forEach((el) => el.innerHTML = newArrowIcon);
    }

    nodePositionHandler(element) {
        if (!element) {
            return;
        }

        let top = 0;
        let left = 0;

        while (element) {
            if (element.tagName) {
                top = top + element.offsetTop;
                left = left + element.offsetLeft;
                element = element.offsetParent;
                continue;
            }
            element = element.parentNode;
        }

        return top;
    }

    createOrSelectElement(query, parent) {
        let element = parent.querySelector(query);
        if (element) return element;
        element = this.createElementFromSelector(query);
        parent.appendChild(element);
        return element;
    }

    createElementFromSelector(selector) {
        const pattern = /^(.*?)(?:#(.*?))?(?:\.(.*?))?(?:@(.*?)(?:=(.*?))?)?$/;
        const matches = selector.match(pattern);
        const element = document.createElement(matches[1] || 'div');
        if (matches[2]) element.id = matches[2];
        if (matches[3]) element.className = matches[3];
        if (matches[4]) element.setAttribute(matches[4], matches[5] || '');
        return element;
    }

    stopVideo = (video) => {
        if (!video) return;
        video.pause();
        video.currentTime = 0;
    };

    selectArray = (element, query) => Array.from(element?.querySelectorAll(query) ?? []);

    // Needs to have eventAggregator imported to work.
    resizeByNavbar(obj, parent, navSelector = '#navigation-bar', height) {
        const navbar = document.querySelector(navSelector);
        const app = document.querySelector(parent);
        this.changePaddingByNavbar(app, navbar, height);
        this.eventAggregator.publish('observe-element', ({ selector: navSelector }));
        obj.navBarSubscriber = obj.eventAggregator.subscribe(`size-changed-${navSelector.removeSelectorSymbol()}`, x => this.changePaddingByNavbar(app, navbar, height));
    }

    changePaddingByNavbar = (app, navbar, height) => {
        app.style.marginTop = navbar.clientHeight.toString() + 'px';
        if (!height) return;
        app.style.height = `calc( ${height} - ${app.style.marginTop})`;
    };

    // ----- General Functionality -----

    checkPreviousAttempts(hasFailedAttempts, originalMessage, action) {
        const type = hasFailedAttempts ? ToastType.INFO : ToastType.SUCCESS;
        if (hasFailedAttempts) originalMessage += ` Previous ${action} attempts were detected. For security, please review your account access and passwords. `;
        this.toastService.showToast(type, originalMessage);
    }

    getTrustPilotStarRatingVariables(pageContentArea) {
        this.trustPilotStarRating = pageContentArea.find(x => x.key === 'HOME_TRUST_PILOT_RATING')?.markup ?? '4.8';
        this.trustPilotStarRating = this.helper.sanitizeHtml(this.trustPilotStarRating, true);
        this.trustPilotStarRating = Number(this.trustPilotStarRating).toFixed(1).toFloat();
        this.amountOfStars = this.trustPilotStarRating.toInt();
        const checker = (this.trustPilotStarRating % 1 !== 0) && (this.trustPilotStarRating - this.trustPilotStarRating?.toInt()).toFloat().toFixed(1);
        this.halfStar = checker < 0.6;
        this.semisesquiStar = !this.halfStar;
    }

    getWidth = () => window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

    getResolutions(obj, suffix = '') {
        const resolutions = Object.keys(constants).map(x => x.split('device__')[1]).filter(x => x);
        resolutions.forEach(x => obj[x + suffix] = constants[`device__${x}`]);
    }

    widthHandler(obj) {
        const width = this.getWidth();
        const isDesktop = width > this.desktop;
        const isTablet = width > this.phone && width <= this.desktop;
        const isPhone = width <= this.phone;
        Object.assign(obj, { isDesktop, isTablet, isPhone });
    }

    disposeAll = (events) => {
        events?.forEach(x => {
            x?.dispose();
        });
    };

    disposeAllSubscribers(obj) {
        this.disposeAll(this.getVariablesByName(obj, ['Subscriber']));
    }

    subscribeEvents(obj, events) {
        Object.keys(events).forEach(event => {
            const eventName = this.camelize(event.replaceAll('-', ' ')) + 'Subscriber';
            obj[eventName] = obj.eventAggregator.subscribe(event, events[event]);
        });
    }

    subscribeSignalRConnections(connection, events) {
        if (!connection) {
            return;
        }
        connection.listeners = Object.keys(events);
        connection.listeners.forEach(event => {
            connection.on(event, events[event]);
        });
    }

    clearTimeouts(value) {
        if (!value) return;
        for (const timeout of value) {
            clearTimeout(timeout);
        }
    }

    applyMany(val, ...funcs) {
        funcs.forEach(func => val = this[func]?.(val) || val);
        return val;
    }

    ifExists = (value, callback) => value ? callback() : undefined;

    parseIfExists = (value) => this.ifExists(value, () => JSON.parse(value));

    compareBy = (var1, var2, modifier) => modifier(var1) === modifier(var2);

    switchClass = (element, arrayClasses, revert, oneAction) => {
        if (revert) {
            if (!oneAction) element.classList.remove(arrayClasses[1]);
            element.classList.add(arrayClasses[0]);
            return;
        }
        element.classList.remove(arrayClasses[0]);
        if (!oneAction) element.classList.add(arrayClasses[1]);
    };

    isEllipsisActive = (container, className) => {
        const targetChild = container.querySelector(className);
        return container.scrollWidth > container?.clientWidth || targetChild?.scrollWidth > targetChild?.clientWidth;
    };

    debounce = (obj, fieldName, timeoutName, time = 2000, callback = null) => {
        obj[fieldName] = true;
        if (obj[timeoutName]) {
            clearTimeout(obj[timeoutName]);
            obj[timeoutName] = null;
        }
        obj[timeoutName] ??= setTimeout(async() => {
            obj[timeoutName] = null;
            obj[`${fieldName}Inner`] = true;
            const response = await callback?.();
            obj[`${fieldName}Inner`] = false;
            if (!response) obj[fieldName] = false;
        }, time);
    };

    actionAfterHandler = (obj, variableName, checking, callback = null, attempts = 4, time = 1000, instant = true, type) => {
        const fieldName = `${variableName}Checking`;
        const intervalName = `${variableName}Interval`;
        const attemptsName = `${intervalName}Attempts`;
        if (obj[intervalName]) return;

        obj[attemptsName] = 0;
        obj[fieldName] = true;

        const checkHandler = () => {
            if (obj[attemptsName] === attempts) {
                clearInterval(obj[intervalName]);
                obj[intervalName] = null;
                obj[fieldName] = false;
                return true;
            }
            const check = type === 'query' ? document.querySelector(checking) : checking?.();
            if (check) {
                callback?.(check);
                clearInterval(obj[intervalName]);
                obj[intervalName] = null;
                return true;
            }
            obj[attemptsName]++;
        };

        if (instant && checkHandler()) return;

        obj[intervalName] ??= setInterval(() => {
            if (checkHandler()) return;
        }, time);
    };


    actionAfterExists = (obj, variableName, query, callback = null, attempts = 4, time = 1000, instant = true) => {
        return this.actionAfterHandler(obj, variableName, query, callback, attempts, time, instant, 'query');
    };

    actionAfterCondition = (obj, variableName, condition, callback = null, attempts = 4, time = 1000, instant = true) => {
        return this.actionAfterHandler(obj, variableName, condition, callback, attempts, time, instant, 'condition');
    };

    isBoolean = (value) => value === false || value === true;

    checkWidthForDisplayingCards = (width, pageName) => {
        if (pageName === 'products') {
            return (width > this.widescreen && width < 1256) || (width > this.phone && width < this.desktop) ? 16 : 15;
        }
        if (pageName === 'coupons') {
            return width <= this.phone ? 6 : (width > this.smalldesktop && width < this.widescreenplus) || (width > this.phone && width < this.smalltablet) ? 16 : 15;
        }
        if (pageName === 'blogNoResults') {
            return width <= this.phone ? 1 : width < this.desktop ? 2 : 3;
        }
        if (pageName === 'blog') {
            if (width >= this.desktop) return 9;
            else if (width > this.phone && width < this.desktop) return 6;
            else if (width <= this.phone) return 5;
        }
    };

    sanitizeHtml = (value, noHtml = false) => {
        if (!value) return '';
        const params = {
            allowedTags: ['div', 'p', 'img', 'a', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'section', 'li', 'ol', 'ul',
                'br', 'i', 'span', 'strong', 'table', 'tbody', 'td', 'tfoot', 'th', 'thead', 'tr'],
            allowedAttributes: {
                a: ['href', 'name', 'target', 'class'],
                img: ['src', 'alt', 'class']
            },
            selfClosing: ['img'],
            allowedSchemes: ['http', 'https', 'ftp', 'mailto', 'data']
        };

        if (noHtml) {
            params.allowedTags = [];
            params.allowedAttributes = {};
        }
        return sanitizeHtml(value, params);
    };

    injectScript = (id, src, async = false, crossorigin = false) => {
        const el = document.getElementById(id);
        if (el) return;
        const script = document.createElement('script');
        script.setAttribute('id', id);
        script.setAttribute('type', 'text/javascript');
        if (async) script.setAttribute('async', 'true');
        if (crossorigin) script.setAttribute('crossorigin', 'anonymous');
        script.setAttribute('src', src);
        document.body.appendChild(script);
    };

    isWrappedWithHtmlTags = (str) => /<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/.test(str);

    validatorCheckOneCondition = (field, results) => !results.some(x => x.propertyName === field && !x.valid);

    emailChecker = (value) => {
        const emailMissspellObj = emailMisspelled({ domains: DomainsOverride });
        return emailMissspellObj(value)?.length;
    };

    emailDisallowCon = (value) => emailDisallowConRegExr().test(value);

    handlePasteNumberValidation = (event, element) => {
        if (!element) return;
        const clipData = event.clipboardData || window.clipboardData;
        const text = clipData?.getData('text/plain');
        if (!text?.toLowerCase()?.includes('e') && !text?.includes('+') && !text?.includes('-')) return true;
        event?.preventDefault();
        return false;
    };

    getCardImageType = (cardType) => {
        const cardTypeMappings = {
            'vi': 'visa',
            've': 'visa',
            'vd': 'visa',
            'visa': 'visa',
            'visaelectron': 'visa',
            'mc': 'mastercard',
            'mastercard': 'mastercard',
            'am': 'amex',
            'amex': 'amex',
            'american express': 'amex',
            'dc': 'diners',
            'diners': 'diners',
            'diners club': 'diners',
            'di': 'discover',
            'discover': 'discover',
            'maestro': 'maestro',
            'jcb': 'jcb',
            'mada': 'mada'
        };

        const simplifiedType = cardTypeMappings[cardType?.toLowerCase()] || 'generic';
        return `/payment-methods/${simplifiedType}.svg`;
    };

    async handleCurrency(previousCurrency, preferredCurrency, method) {
        let currencyToSent = '';
        if (['ideal', 'interac', 'zelle', 'bluesnap-checkout', 'sofort'].includes(method.paymentMethod.reference)) {
            switch (method.paymentMethod.reference) {
                case 'ideal':
                case 'sepa-bluesnap-checkout':
                case 'giropay-bluesnap-checkout':
                case 'sofort':
                    currencyToSent = 'EUR';
                    break;
                case 'interac':
                    currencyToSent = 'CAD';
                    break;
                case 'zelle':
                case 'electronic-check-bluesnap-checkout':
                case 'webmoney-bluesnap-checkout':
                case 'google-pay-bluesnap-checkout':
                case 'skrill-bluesnap-checkout':
                    currencyToSent = 'USD';
                    break;
                case 'alipay-bluesnap-checkout':
                    currencyToSent = 'CNY';
                    break;
                case 'boleto-bancario-bluesnap-checkout':
                    currencyToSent = 'BRL';
                    break;
                default:
                    break;
            }
        } else {
            previousCurrency !== 'undefined' ? currencyToSent = previousCurrency : '';
        }

        this.eventAggregator.publish('force-currency',
            { currency: currencyToSent, oldCurrency: preferredCurrency, currentPaymentMethodSelected: method });
    }

    getCookieValue = (cookieName) => document.cookie.match(`(^|;)\\s*${cookieName}\\s*=\\s*([^;]+)`)?.pop();

    /**
     * @param connection
     * @param {string[]} listeners
     */
    killSignalRListeners = (connection, listeners) => {
        listeners ??= connection.listeners;
        listeners.forEach(x => {
            connection?.off(x);
        });
    };

    addTransformStyling = (parentSpanContainer, childSpanContainer) => {
        let translateStyling = '';
        if (childSpanContainer.offsetWidth <= parentSpanContainer.offsetWidth) {
            return;
        }

        translateStyling = `translateX(calc(${parentSpanContainer.offsetWidth}px - 100%))`;

        const existingTransformStyling = document.getElementById('transform-style');
        if (!existingTransformStyling) {
            const transformStyling = document.createElement('style');
            transformStyling.setAttribute('id', 'transform-style');
            transformStyling.innerHTML = `.translate-x-animation { animation-duration: ${(childSpanContainer.offsetWidth - parentSpanContainer.offsetWidth) / 40}s; } @keyframes translateXAnimation { 0% { transform: translateX(0); } 100% { transform: ${translateStyling}; } }
                @keyframes translateXAnimationReverse { 0% { transform: translateX(-5px); } 100% { transform: translateX(0); } }`;
            document.head.append(transformStyling);
        } else {
            existingTransformStyling.innerHTML = `.translate-x-animation { animation-duration: ${(childSpanContainer.offsetWidth - parentSpanContainer.offsetWidth) / 40}s; } @keyframes translateXAnimation { 0% { transform: translateX(0); } 100% { transform: ${translateStyling}; } }
            @keyframes translateXAnimationReverse { 0% { transform: translateX(-5px); } 100% { transform: translateX(0); } }`;
        }
        childSpanContainer.classList.add('d-inline-block');
        childSpanContainer.classList.remove('translate-x-reverse-animation');
        childSpanContainer.classList.add('translate-x-animation');
    };

    removeTransformStyling = (parentSpanContainer, childSpanContainer) => {
        if (childSpanContainer.offsetWidth <= parentSpanContainer.offsetWidth) {
            return;
        }

        childSpanContainer.classList.remove('translate-x-animation');
        childSpanContainer.classList.add('translate-x-reverse-animation');
        childSpanContainer.addEventListener('animationend', (e) => {
            if (e.animationName === 'translateXAnimationReverse') {
                childSpanContainer.classList.remove('d-inline-block');
            }
        });
    };

    /**
     * Check if the current date modified by the time parameter is before or after the date parameter
     *
     * @param {Date} date
     * @param {int} time - The amount of time to subtract from the current date
     * @param {'seconds' | 'minutes' | 'hours' | 'days'} scale
     * @param {'before' | 'after'} type
     * @param {'add' | 'subtract'} action - Whether the time param should add or subtract the current date
     * @param {boolean} include - Whether the comparison should pass if the dates are equal
     */
    dateRangeComparison = (date, time, scale = 'seconds', type = 'before', action = 'subtract', include = true) => {
        const SECOND = 1000;
        const MINUTE = SECOND * 60;
        const HOUR = MINUTE * 60;
        const DAY = HOUR * 24;

        let modDate = new Date(Date.now());

        switch (scale) {
            case 'minutes':
                action === 'subtract' ? modDate -= MINUTE * time : modDate += MINUTE * time;
                break;
            case 'hours':
                action === 'subtract' ? modDate -= HOUR * time : modDate += HOUR * time;
                break;
            case 'days':
                action === 'subtract' ? modDate -= DAY * time : modDate += DAY * time;
                break;
            default:
                action === 'subtract' ? modDate -= SECOND * time : modDate += SECOND * time;
                break;
        }

        if (type === 'before') {
            if (include) return modDate <= date;
            return modDate < date;
        }

        if (include) return modDate >= date;
        return modDate > date;
    };

    /**
     * @param {Element} elem
     *
     * @returns {boolean}
     */
    isScrollableAtBottom = (elem) => {
        return elem.scrollTop + elem.clientHeight >= elem.scrollHeight;
    };

    /**
     * @param {Element} elem
     *
     * @returns {boolean}
     */
    isScrollableAtTop = (elem) => {
        return elem.scrollTop === 0;
    };

    /**
     * @param {string} elementSelector
     * @param {string} normalStyle
     * @param {string | null} topStyle
     * @param {string | null} bottomStyle
     *
     * @returns {void}
     */
    dynamicScrollStyle = (elementSelector, normalStyle, topStyle, bottomStyle) => {
        if (!topStyle && !bottomStyle) return;
        const elem = document.querySelector(elementSelector);
        if (!elem) return;
        this.elementDynamicScrollStyle(elem, normalStyle, topStyle, bottomStyle);
    };

    dynamicScrollStyleByProxy = (proxyElem, elem, normalStyle, topStyle, bottomStyle) => {
        this.elementDynamicScrollStyle(elem, normalStyle, topStyle, bottomStyle, proxyElem);
    };

    /**
     * @param {HTMLElement} elem
     * @param {string} normalStyle
     * @param {string | null} topStyle
     * @param {string | null} bottomStyle
     * @param {HTMLElement | null} proxyElem
     *
     * @returns {void}
     */
    elementDynamicScrollStyle = (elem, normalStyle, topStyle, bottomStyle, proxyElem = null) => {
        const onScroll = () => {
            const isAtBottom = this.isScrollableAtBottom(proxyElem ?? elem);
            const isAtTop = this.isScrollableAtTop(proxyElem ?? elem);

            if (isAtBottom && bottomStyle) {
                if (!elem.className.includes(bottomStyle)) {
                    if (elem.className.length > 0 && !elem.className.endsWith(' ')) {
                        elem.className = elem.className.concat(' ');
                    }

                    elem.className = elem.className.concat(bottomStyle);
                }
                return;
            } else if (!isAtBottom && bottomStyle && elem.className.includes(bottomStyle)) {
                elem.className = elem.className.replace(bottomStyle, '');
            }

            if (isAtTop && topStyle) {
                if (!elem.className.includes(topStyle)) {
                    if (elem.className.length > 0 && !elem.className.endsWith(' ')) {
                        elem.className = elem.className.concat(' ');
                    }

                    elem.className = elem.className.concat(topStyle);
                }
                return;
            } else if (!isAtTop && topStyle && elem.className.includes(topStyle)) {
                elem.className = elem.className.replace(topStyle, '');
            }

            if (!elem.className.includes(normalStyle)) {
                if (elem.className.length > 0 && !elem.className.endsWith(' ')) {
                    elem.className = elem.className.concat(' ');
                }

                elem.className = elem.className.concat(normalStyle);
            }
        };

        if (proxyElem) {
            proxyElem.addEventListener('scroll', onScroll);
            return;
        }

        elem.addEventListener('scroll', onScroll);
    };

    hideContentElementIfNoHeight = (el) => el.offsetHeight === 0 ? 'd-none' : '';

    /**
     * @param {Product | SteamInventoryItem} product
     * @returns boolean
     */
    isSteamProduct = (product) => {
        return product.assetId && product.instanceId;
    };

    /**
     * @param {Product | SteamInventoryItem} a
     * @param {Product | SteamInventoryItem} b
     *
     * @returns boolean
     */
    areProductsEqual = (a, b) => {
        const isASteam = this.isSteamProduct(a);
        const isBSteam = this.isSteamProduct(b);

        if (!isASteam && isBSteam || isASteam && !isBSteam) return false;

        if (!isASteam && !isBSteam) {
            return a.id === b.id || ((a.serviceFullName || b.serviceFullName) && a.serviceFullName === b.serviceFullName);
        }

        if (isASteam && isBSteam) {
            return a.assetId === b.assetId && a.productId === b.productId;
        }

        return false;
    };

    /**
     * Function that adds products that are not in the old array and removes the ones that are not in the new array,
     * also can compare properties of the products and update their values.
     * This function is meant for products, but it could be expanded for other types of objects.
     *
     * @param {(Product | SteamInventoryItem)[]} oldArray - The array to update
     * @param {(Product | SteamInventoryItem)[]} newArray - The array to compare against
     * @param {string[]} properties - The nested properties to compare (so far it only compares properties on the first level, needs to be expanded for deeper comparisons)
     *
     * @returns {(Product | SteamInventoryItem)[]}
     */
    compareProductArrays = (oldArray, newArray, properties = []) => {
        newArray.forEach(x => {
            const index = oldArray.findIndex(y => this.areProductsEqual(x, y));

            if (index === -1) {
                oldArray.push(x);
                return;
            }

            const oldProduct = oldArray[index];

            if (this.isSteamProduct(x)) return;

            properties.forEach(property => {
                if (x[property] === oldProduct[property]) return;
                oldArray[index][property] = x[property];
            });
        });

        return oldArray.filter(oldItem =>
            newArray.some(newItem => this.areProductsEqual(oldItem, newItem))
        );
    };

    showPopup(props) {
        this.eventAggregator.publish('bottom-popup', props);
    }

    handleDropdownWithAnimation = (pageElement, option, active = true, classOptionName = 'options-children') => {
        if (!pageElement) return;
        const childrenElement = pageElement.querySelector(`.${classOptionName}-${option.option}`);
        if (!childrenElement) return;

        this.timeouts = [this.styleHeightTimeout, this.hiddenBounceTimeout, this.hiddenBounceTimeout1, this.hiddenBounceTimeout2];
        this.clearTimeouts(this.timeouts);

        childrenElement.classList.remove('hidden__bounce');
        if (active) {
            const height = option.children.length * 50;
            childrenElement.style.height = `${height + 10}px`;
            childrenElement.classList.remove('hidden');
            this.styleHeightTimeout = setTimeout(() => childrenElement.style.height = `${height}px`, 300);
            return;
        }
        childrenElement.style.height = null;
        childrenElement.classList.add('hidden');
        this.hiddenBounceTimeout1 = setTimeout(() => {
            childrenElement.classList.toggle('hidden__bounce');
            this.hiddenBounceTimeout2 = setTimeout(() => childrenElement.classList.toggle('hidden__bounce'), 180);
        }, 200);
    };

    /**
     * @param {any} p1
     * @param {any} p2
     * @returns {boolean}
     */
    propertiesEqual = (p1, p2) => {
        if (!p1 && !p2) return true;
        return p1 === p2;
    };

    getNameFromEnum(enumObject, enumId) {
        for (const [name, number] of Object.entries(enumObject)) {
            if (number === enumId) {
                return name;
            }
        }
        return null;
    }

    startsWithVowel(word) {
        return /^[aeiou]/i.test(word);
    }

    fetchFingerprintForUser = async() => {
        if (environmentName() === 'local') return;
        if (!this.fingerprint) {
            this.fingerprint = await FingerprintJS.load({
                apiKey: fingerprintApiKey(),
                scriptUrlPattern: [
                    `${fingerprintDomainUrl()}web/v<version>/<apiKey>/loader_v<loaderVersion>.js`,
                    defaultScriptUrlPattern
                ],
                endpoint: [
                    fingerprintDomainUrl().slice(0, -1),
                    defaultEndpoint
                ]
            });
        }
        const result = await this.fingerprint.get();
        if (!result) return;
        return result.visitorId;
    };

    saveWindowLocalStorageValue(localStorage, value) {
        window.localStorage[localStorage] = value;
    }

    getWindowLocalStorageValue(localStorage) {
        return window.localStorage[localStorage];
    }

    destroyWindowLocalStorageValue(localStorage) {
        window.localStorage.removeItem(localStorage);
    }

    isMobileDevice() {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }

    swapElements = (arr, pos1, pos2) => {
        const temp = arr[pos1];
        arr[pos1] = arr[pos2];
        arr[pos2] = temp;
        return arr;
    };

    /**
     * Compares the product IDs of two OrderProducts.
     *
     * @param {OrderProduct} a - The first object to compare.
     * @param {OrderProduct} b - The second object to compare.
     * @returns {boolean} Returns true if the product IDs are equal, otherwise returns false.
     */
    compareProductsIds = (a, b) => a.productId === b.productId;

    /**
     * Validates if a product can be added to the cart.
     *
     * @param {OrderProduct} product - The product to be added.
     * @param {OrderProduct[]} cart - The current cart.
     * @returns {string | null} - Returns an error message if the product cannot be added, otherwise returns null.
     */
    validateOnProductAdd = (product, cart) => {
        if (!cart || cart.length <= 0) return;
        const compareWithCart = (p, callback) => callback(p) && cart.find(callback);
        const cartProduct = cart.find(x => this.compareOrderProducts(x, product));

        const checkCategory = (category, name, message) => {
            if (compareWithCart(product, x => x.productCategory?.name === category) ||
                compareWithCart(product, x => x.serviceFullName?.includes(name))) {
                return `Cannot add more than one ${message} per order.`;
            }
        };

        let duplicateError = checkCategory(ProductCategoryType.UniqueNames, 'Dynamic Unique Names', 'unique name');
        duplicateError ||= checkCategory(ProductCategoryType.Services, 'Dynamic Service', 'service');

        if (duplicateError) return duplicateError;

        if (!cartProduct) {
            return;
        }

        if (this.propertiesEqual(cartProduct.isSell, product.isSell)) {
            if (this.propertiesEqual(cartProduct.character, product.character)) {
                return 'One or more products with the same category and character name already included in the cart.';
            }

            return;
        }

        return 'Cannot swap the same product. One or more products already in the cart in the opposite swap category.';
    };

    /**
     * @param {CustomOrderProduct} a
     * @param {CustomOrderProduct} b
     * @returns boolean
     */
    compareOrderProducts = (a, b) => a.productId === b.productId && this.compareProperties(a, b, ['serviceFullName'], ['isSell']);

    /**
     * @param {CustomOrderRequest} orderData
     * @param {CustomOrderProduct[]} cart
     *
     * @returns {string | null}
     */
    validateWithUserCart = (orderData, cart) => {
        if (!cart) {
            return null;
        }

        for (const product of orderData.products) {
            const result = this.validateOnProductAdd(product, cart);
            if (!result) continue;
            return result;
        }

        return null;
    };

    validateWithCustomCurrency = (cart, currencyProduct) => {
        let customCurrencyValidation = false;
        const customCurrency = cart.find(x => x.name === 'Custom Currency');

        if (!customCurrency) return true;

        if (customCurrency) {
            const dynamicList = JSON.parse(customCurrency.dynamicList);
            const gameId = Number(dynamicList.find(x => x.name === 'GameId').value);
            customCurrencyValidation = currencyProduct.gameId !== gameId;
        }
        return customCurrencyValidation;
    };

    findNearestNumericValue = (inputNum, valueArray) => {
        const inputNumFloat = parseFloat(inputNum);

        const lastRange = valueArray[valueArray.length - 1];
        const lastRangeValues = lastRange.display.split('-').map(parseFloat);
        const lastRangeMax = lastRangeValues.length === 1 ? lastRangeValues[0] : lastRangeValues[1];

        if (inputNumFloat > lastRangeMax) {
            return lastRange;
        }

        const closestIndex = valueArray.findIndex(item => {
            if (item.display === 'All') return false;

            const rangeValues = item.display.split('-').map(parseFloat);
            if (rangeValues.length === 1) return inputNumFloat === rangeValues[0];

            return inputNumFloat >= rangeValues[0] && inputNumFloat <= rangeValues[1];
        });

        return valueArray[closestIndex];
    };

    async handlePendingRequest(component, apiCall) {
        let isRequestPending = this.pendingRequests.get(component);
        if (isRequestPending === undefined) {
            isRequestPending = false;
            this.pendingRequests.set(component, isRequestPending);
        }

        while (isRequestPending) {
            await new Promise(resolve => setTimeout(resolve, 100));
            isRequestPending = this.pendingRequests.get(component);
        }

        this.pendingRequests.set(component, true);

        try {
            return await apiCall();
        } finally {
            this.pendingRequests.set(component, false);
        }
    }

    async fetchData(api, path, serviceName, forceFetch = false) {
        if (!this.serviceState[serviceName]) {
            this.serviceState[serviceName] = {
                responseData: null,
                isRequestPending: false,
                requestQueue: []
            };
        }

        const { responseData, isRequestPending, requestQueue } = this.serviceState[serviceName];

        if (responseData && !forceFetch) {
            return responseData;
        }

        if (isRequestPending) {
            return new Promise((resolve) => {
                requestQueue.push(resolve);
            });
        }

        try {
            this.serviceState[serviceName].isRequestPending = true;
            this.serviceState[serviceName].responseData = await api.doGet(`${path}`);
            this.processRequestQueue(serviceName);
            return this.serviceState[serviceName].responseData;
        } finally {
            this.serviceState[serviceName].isRequestPending = false;
        }
    }

    processRequestQueue(serviceName) {
        const { responseData, requestQueue } = this.serviceState[serviceName];
        while (requestQueue.length > 0) {
            const resolve = requestQueue.shift();
            if (resolve) {
                resolve(responseData);
            }
        }
    }

    clearServiceQueueState(serviceName) {
        this.serviceState[serviceName] = null;
    }

    addLoadingComponent(page) {
        this.loadingState.push(page);
    }

    validateLoading(page) {
        if (this.loadingState.includes(page)) this.loadingState.splice(this.loadingState.indexOf(page), 1);
        if (!this.loadingState.length) {
            this.eventAggregator.publish('page-loaded');
        }
    }

    resetLoading = () => this.loadingState = [];

    getIcon = (category, section) => section[category.name];

    getScreenSize = () => {
        return {
            height: this.getScreenHeight(),
            width: this.getScreenWidth()
        };
    };

    getScreenHeight = () => window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

    getScreenWidth = () => window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

    compareObj = (obj1, obj2) => {
        return Object.keys(obj1).every(x => obj1[x] === obj2[x]);
    };

    /**
     * @param {Product} product
     *
     * @returns {number}
     */
    getProductMaxQuantity = (product) => {
        if (product.maximum > product.quantity) {
            return product.quantity;
        }

        if (typeof product.maximum === 'string') {
            return parseInt(product.maximum);
        }

        return product.maximum;
    };

    async handleAccountOrderValidation(cart, orderService, category, isSell) {
        const orderId = cart?.find(x => x.orderId)?.orderId;
        const orderInfo = orderId ? await orderService.getById(orderId) : {};
        const [ sellProducts, purchaseProducts ] = orderInfo?.products ? this.splitByCondition(orderInfo.products, x => x.isSell) : [ [], [] ];
        const cartPurchaseProducts = cart?.filter(x => x.isSell === false);
        const cartSellProducts = cart?.filter(x => x.isSell === true);

        if (isSell && category === ProductCategoryType.Accounts && (orderInfo?.transactionType === OrderTransactionType.Swap || purchaseProducts?.length > 0 || cartPurchaseProducts?.length > 0)) {
            this.toastService.showToast(ToastType.INFO, 'Can\'t add sell account products to an active swap or purchase order.');
            return true;
        }
        if (!isSell && ((sellProducts?.length > 0 && sellProducts?.some(x => x.product.productCategory.name === ProductCategoryType.Accounts)) || (cartSellProducts?.length > 0 && cartSellProducts?.some(x => x.productCategory.name === ProductCategoryType.Accounts)))) {
            this.toastService.showToast(ToastType.INFO, 'Can\'t add items from purchase categories with an active sell account order.');
            return true;
        }
        return false;
    }

    handleTextAreaAutoGrowth(parentElement, field, customClass, mainContainer, informationFields) {
        const textArea = parentElement?.querySelector('textarea');
        if (!textArea) return;
        if (!textArea.value) {
            textArea.style.paddingRight = parentElement.style.height = parentElement.style.maxHeight = '';
            return;
        }
        parentElement.style.height =
            textArea.scrollHeight < 48
                ? '50px'
                : `${textArea.scrollHeight + 20}px`;
        if (field || customClass) {
            const alternative = '-alternative';
            const checkMarkElement = mainContainer?.querySelector(`.textarea-checkmark-${!field ? customClass : informationFields.findIndex(x => x === field)}`);
            if (checkMarkElement) textArea.scrollHeight < 48 ? checkMarkElement.classList?.remove(`circle-icon-textarea${alternative}`) : checkMarkElement.classList?.add(`circle-icon-textarea${alternative}`);
        }
        parentElement.style.maxHeight = '82px';
        textArea.style.paddingRight = textArea.scrollHeight + 20 > 50 ? '0' : null;
    }

    addLeadingZeroToNumber = (number) => number < 10 ? `0${number}` : number;

    getDistanceBetweenTwoElements = (element1, element2, offset = 0) => {
        const rect1 = element1.getBoundingClientRect();
        const rect2 = element2.getBoundingClientRect();
        const xDistance = rect2.left + rect2.width / 2 - (rect1.left + rect1.width / 2);
        const yDistance = rect2.top + rect2.height / 2 - (rect1.top + rect1.height / 2);
        return (Math.sqrt(xDistance * xDistance + yDistance * yDistance)) - offset;
    };

    /**
     * @param {number} length
     * @returns {string}
     */
    generateNonce(length) {
        let nonce = '';

        const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

        for (let i = 0; i < length; i++) {
            nonce += possible.charAt(Math.floor(window.crypto.getRandomValues(new Uint32Array(1))[0] / (0xffffffff + 1) * possible.length));
        }

        return nonce;
    }

    transformStaticPageRoute = (name, removeSellInName = false) => {
        let lowerName = name.toLowerCase();

        if (removeSellInName) {
            lowerName = lowerName.replaceAll('sell ', '');
        }

        lowerName = lowerName.replaceAll(' ', '-');

        if (lowerName.includes('sell')) {
            const hyphenIndex = lowerName.indexOf('-');
            if (hyphenIndex !== -1) {
                lowerName = `${lowerName.substring(0, hyphenIndex)}/${lowerName.substring(hyphenIndex + 1)}`;
            }
        }

        return lowerName;
    };

    combineApplicationLdJsonSchemasIntoOne = (schema, router) => {
        const existingGlobalSchema = document.getElementById('chicks-gold-schema');
        const existingGlobalSchemaText = existingGlobalSchema?.textContent || existingGlobalSchema?.innerText;
        const existingGlobalSchemaParsed = existingGlobalSchemaText ? JSON.parse(existingGlobalSchemaText) : null;
        if (existingGlobalSchema) existingGlobalSchema.remove();

        const globalSchema = document.createElement('script');
        globalSchema.setAttribute('id', 'chicks-gold-schema');
        globalSchema.type = 'application/ld+json';
        const combinedSchemas = `{
            "@context": "https://schema.org/",
            "@graph": [
                ${schema}${existingGlobalSchemaParsed ? ',' : ''}
                ${existingGlobalSchemaParsed?.['@graph'] ? existingGlobalSchemaParsed['@graph'].map(obj => JSON.stringify(obj)).join(',') : ''}
            ]
        }`;

        //Check for duplicated schemas and remove
        const combinedSchemasClone = JSON.parse(combinedSchemas);
        const seenTypes = {};
        const reversedSchema = combinedSchemasClone['@graph'].slice().reverse();

        combinedSchemasClone['@graph'] = reversedSchema.filter(item => {
            if (seenTypes[item['@type']]) return false;
            seenTypes[item['@type']] = true;
            return true;
        }).reverse();

        let finalizedCombinedSchemas = JSON.stringify(combinedSchemasClone, null, 4);

        if (router) {
            const schemasArray = [];

            if (!router.currentInstruction.config.hasBlogPostSchema) {
                schemasArray.push('BlogPosting', 'Person');
            }

            if (!router.currentInstruction.config.hasProductSchema) {
                schemasArray.push('Product', 'Offer', 'AggregateRating', 'Brand');
            }

            finalizedCombinedSchemas = this.removeFromGlobalSchema(combinedSchemasClone, schemasArray);
        }

        globalSchema.innerHTML = finalizedCombinedSchemas;
        document.head.appendChild(globalSchema);
    };

    removeFromGlobalSchema = (mainSchema, schemasArray) => {
        if (!mainSchema) return;
        mainSchema['@graph'] = mainSchema['@graph'].filter(obj => !schemasArray.includes(obj['@type']));
        return JSON.stringify(mainSchema, null, 4);
    };

    addBrowserInfoData() {
        return {
            browserAcceptHeader: 'application/json',
            browserJavaEnabled: window.navigator.javaEnabled(),
            browserJavaScriptEnabled: true,
            browserLanguage: window.navigator.language,
            browserColorDepth: window.screen.colorDepth.toString(),
            browserTZ: new Date().getTimezoneOffset().toString(),
            browserScreenWidth: window.screen.width.toString(),
            browserScreenHeight: window.screen.height.toString(),
            browserUserAgent: window.navigator.userAgent
        };
    }

    includesNumber = (number, array) => array.includes(number);

    setMinimumBalance = (user) => {
        if (!user) return user;
        user.balance = user.balance < 0.1 ? 0 : user.balance;
        user.pendingBalance = user.pendingBalance < 0.1 ? 0 : user.pendingBalance;
        return user;
    };

    mapAuViewModel = (container, elementsArray, selector) => {
        if (!container) return;
        const elements = this.selectArray(container, selector ?? elementsArray?.[0]);
        if (!elements?.length) return;
        return elements?.map(x => {
            for (const element of elementsArray) {
                const auElement = x.au?.[element]?.viewModel;
                if (auElement) return auElement;
            }
        }).filter(x => x);
    };

    fetchIPsForCustomer = async() => {
        if (debug()) return;
        let ipv4Address;
        let ipv6Address;

        try {
            const ipv4Fetch = await fetch('https://api.ipify.org');
            ipv4Address = await ipv4Fetch.text();
        } catch (error) {
            //skip
        }

        try {
            const ipv6Fetch = await fetch('https://api6.ipify.org');
            ipv6Address = await ipv6Fetch.text();
        } catch (error) {
            //skip
        }

        return { ipv4Address, ipv6Address };
    };

    findMarkupByKey = (pageContentArea, key) => pageContentArea?.find(x => x?.key === key)?.markup;

    createRouteObject = (name, moduleId, route, title) => {
        const realName = Array.isArray(name) ? name[0] : name;
        const toRoute = this.toRoute(realName.toLowerCase());

        return ({
            route: route ?? toRoute,
            name: toRoute,
            moduleId: moduleId,
            title: title ?? realName
        });
    };
}
