export class CamelCaseValueConverter {
    toView(value, withoutSnake = false) {
        if (!value) return;

        if (withoutSnake) {
            return value.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (_, chr) => chr.toUpperCase());
        }

        const snakeToCamel = (string) => {
            const splitStringArr = string.split('_');
            const builtStr = splitStringArr.reduce((acc, curr, i) => {
                curr = i !== 0 ? curr[0].toUpperCase() + curr.slice(1) : curr;
                return acc + curr;
            }, '');
            return builtStr;
        };

        const convertResponse = (response) => {
            if (response) {
                const parentKeys = Object.keys(response);
                parentKeys.forEach((key) => {
                    const currentObj = response[key];
                    delete response[key];
                    const newKey = snakeToCamel(key);
                    response[newKey] = currentObj;
                    if (typeof response[newKey] === 'object') {
                        convertResponse(response[newKey]);
                    }
                });
                return response;
            }
        };

        return convertResponse(value);
    }
}
