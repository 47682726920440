import { inject } from 'aurelia-framework';
import { ApiService } from './api-service';

@inject(ApiService)
export class CurrencyService {
    path = 'Currency';
    currencyRates = [];
    currencyRatesWithoutSpreadFee = [];
    activeCurrencies;
    allActiveCurrencies;

    constructor(api) {
        this.api = api;
    }

    async getAllCurrencyRates(websiteShortCode, withSpreadFee = true) {
        try {
            const currencyResponse = await this.api.doGet(`${this.path}?websiteShortCode=${websiteShortCode}&withSpreadFee=${withSpreadFee}`);
            if (currencyResponse) {
                const targetArray = withSpreadFee ? this.currencyRates : this.currencyRatesWithoutSpreadFee;
                targetArray.push(currencyResponse.rates);
            }
        } catch (e) {
            console.log(e);
        }
    }

    async getConvertionRateToUSD(currency, isSold = false) {
        try {
            return await this.api.doGet(`${this.path}/ConvertionRateToUSD/${currency}?websiteShortCode=CG&isSold=${isSold}`);
        } catch (e) {
            console.log(e);
        }
    }

    getStoredCurrencyRates(currency, withSpreadFee = true) {
        try {
            if (currency === 'USD' || !currency) {
                return 1;
            }
            let foundRate;
            const targetArray = withSpreadFee ? this.currencyRates : this.currencyRatesWithoutSpreadFee;
            for (const rate of targetArray) {
                for (const currencyRate in rate) {
                    if (currencyRate === currency.toLowerCase()) {
                        foundRate = rate[currencyRate];
                    }
                }
            }
            if (!foundRate) {
                return null;
            }
            return foundRate;
        } catch (e) {
            console.log(e);
        }
    }

    async getActiveCurrenciesByWebsite() {
        try {
            if (this.activeCurrencies) {
                return this.activeCurrencies;
            }
            this.activeCurrencies = await this.api.doGet(`${this.path}/GetActiveCurrenciesByWebsite?websiteShortCode=CG`);
            return this.activeCurrencies;
        } catch (e) {
            console.log(e);
        }
    }

    getStoredActiveCurrenciesByWebsite() {
        return this.activeCurrencies;
    }

    async getActiveCurrencies() {
        if (this.allActiveCurrencies) return this.allActiveCurrencies;
        this.allActiveCurrencies = (await this.api.doGet(`${this.path}/GetAllActiveCurrencies`)).map(e => {
            if (e.type === 'C') e.shortName = e.description;
            return e;
        });
        return this.allActiveCurrencies;
    }

    async getCurrencyById(id) {
        try {
            if (!this.activeCurrencies) {
                await this.getActiveCurrenciesByWebsite();
            }
            return this.activeCurrencies.find(c => c.id === id);
        } catch (e) {
            console.log(e);
        }
    }

    async getCurrencyByCode(code) {
        try {
            if (!this.activeCurrencies) {
                await this.getActiveCurrenciesByWebsite();
            }
            return this.activeCurrencies.find(c => c.code === code);
        } catch (e) {
            console.log(e);
        }
    }
}
