import { inject } from 'aurelia-framework';
import { ApiService } from './api-service';

@inject(ApiService)
export class ReferralService {
    path = 'ReferralLink';

    constructor(api) {
        this.api = api;
    }

    async submitReferralLink(url, linkTypeId) {
        return await this.api.doPost(`${this.path}/submit?referralLinkUrl=${url}&linkTypeId=${linkTypeId}`);
    }
}
