import { inject } from 'aurelia-framework';
import { Helper } from 'resources/extensions/helper';

@inject(Helper)
export class CapitalizeTextValueConverter {
    constructor(helper) {
        this.helper = helper;
    }

    toView(value, type = 'first') {
        return this.helper.toCapitalize(value, type);
    }
}
