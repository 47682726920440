import { inject } from 'aurelia-framework';
import { ApiService } from './api-service';
import { Helper } from 'resources/extensions/helper';

@inject(ApiService, Helper)
export class ChargebackService {
    path = 'Chargeback';
    userChargeback;

    constructor(api, helper) {
        this.api = api;
        this.helper = helper;
    }

    async userHasChargeback(userId) {
        if (this.helper.isBoolean(this.userChargeback)) return this.userChargeback;
        this.userChargeback = await this.helper.fetchData(this.api, `${this.path}/UserHasChargeback/${userId}`, 'userHasChargeback');
        return this.userChargeback;
    }
}
