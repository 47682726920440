import { inject } from 'aurelia-framework';
import { ApiService } from './api-service';
import { Helper } from 'resources/extensions/helper';

@inject(ApiService, Helper)
export class UserDocumentService {
    path = 'UserDocument';
    userDocuments;

    constructor(api, helper) {
        this.api = api;
        this.helper = helper;
    }

    getStatus(data, type) {
        if (data.find(x => x.userDocumentVerificationCategoryId === type && !x.userDeleted)?.verified === null) {
            return true;
        } else {
            return false;
        }
    }

    async getUserDocumentsById(id, type) {
        if (!this.userDocuments) {
            this.userDocuments = await this.helper.fetchData(this.api, this.path + '/GetUserDocumentsById/' + id, 'UserDocumentService');
        }
        return this.getStatus(this.userDocuments, type);
    }

    async deleteByUserIdAndCategoryId(userId, categoryId) {
        await this.api.doDelete(this.path + '/DeleteForUserByUserIdAndCategoryId/' + userId + '/' + categoryId);
    }
}
