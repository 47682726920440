import { inject } from 'aurelia-framework';
import { ApiService } from './api-service';

@inject(ApiService)
export class SignalRTrackingService {
    path = 'SignalRTracking';

    constructor(api) {
        this.api = api;
    }

    async processSignalRTracking(sendNotification) {
        return await this.api.doPut(`${this.path}/ProcessSignalRTracking/${sendNotification}`);
    }
}
