import { inject } from 'aurelia-framework';
import { ApiService } from './api-service';
import { SessionService } from './session-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { CamelCaseValueConverter } from 'resources/value-converters/camel-case';
import { Helper } from 'resources/extensions/helper';


@inject(ApiService, SessionService, EventAggregator, CamelCaseValueConverter, Helper)
export class IpAddressService {
    path = 'IpAddress';
    responseData;
    isRequestPending = false;
    requestQueue = [];

    constructor(api, sessionService, eventAggregator, camelCaseValueConverter, helper) {
        this.api = api;
        this.sessionService = sessionService;
        this.eventAggregator = eventAggregator;
        this.camelCaseValueConverter = camelCaseValueConverter;
        this.helper = helper;
    }

    async postIp() {
        const response = await this.api.doPost(this.path, {});
        this.eventAggregator.publish('vpn-detection', { vpn: response.vpn, tor: response.tor, ip: response.ip });
        await this.saveUserProxy(response);
        await this.saveGeoLocation(response);
        return response;
    }

    async getIpData() {
        const response = await this.helper.fetchData(this.api, `${this.path}/GetIpData`, 'IpAddressService');
        if (response) await this.saveGeoLocation(response);
        return response;
    }

    async saveGeoLocation(geolocation) {
        this.sessionService.geolocation = geolocation;
    }

    async saveUserProxy(userProxy) {
        this.sessionService.userProxy = userProxy;
    }
}
