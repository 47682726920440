import { inject } from 'aurelia-framework';
import { ApiService } from './api-service';

@inject(ApiService)
export class BlogService {
    path = 'BlogPost';
    blogs;
    blogsCount;

    constructor(api) {
        this.api = api;
    }

    async getPublishedBlogPosts(recordQuantity, page) {
        return await this.api.doGet(`${this.path}/byWebsite/${recordQuantity}/${page}?websiteShortCode=CG`);
    }

    async getPublishedBlogPostsCount() {
        if (!this.blogsCount) {
            this.blogsCount = await this.api.doGet(`${this.path}/BlogsCountByWebsite?websiteShortCode=CG`);
        }
        return this.blogsCount;
    }

    async getById(id) {
        return await this.api.doGet(this.path + '/' + id);
    }

    async getBlogBySlug(slug) {
        return await this.api.doGet(`${this.path}/GetBySlug/${slug}?websiteShortCode=CG`);
    }

    async updateBlogPostViewCounter(id) {
        return await this.api.doPut(`${this.path}/${id}/AddViewsCounter`, {});
    }

    async getStickyBlogPost() {
        return await this.api.doGet(`${this.path}/StickyPost?websiteShortCode=CG`);
    }

    async filterBlogs(tag, content, page = 1, pageSize, published, order, websites, authorAlias = null, blogTagChilds = null) {
        const options = {
            tag: tag,
            content: content,
            page: page,
            pageSize: pageSize,
            websites: websites,
            published: published,
            order: order,
            authorAlias: authorAlias,
            blogTagChilds: blogTagChilds
        };

        return await this.api.doPost(`${this.path}/DynamicFilter?websiteShortCode=CG`, options);
    }
}
