import { inject } from 'aurelia-framework';
import { ApiService } from './api-service';

@inject(ApiService)
export class HealthService {
    path = 'Health';

    constructor(api) {
        this.api = api;
    }

    async checkApiHealth() {
        return await this.api.doGet(this.path);
    }
}
