import { produce } from 'immer';

/**
 * @type {SwapState}
 */
const initialState = {
    selectedProducts: {
        sellProducts: [],
        purchaseProducts: [],
        sellSkinsProducts: [],
        selectedItem: null
    }
};

/**
 * @param {AppState} state
 * @param {'sellCategory' | 'buyCategory'} categoryType
 * @param {string | undefined | null} category
 *
 * @returns {AppState}
 */
export function setSwapCategory(state, categoryType, category) {
    return produce(state, draftState => {
        draftState.swapState[categoryType] = category;
    });
}

/**
 * @param {AppState} state
 * @param {'Sell' | 'Purchase' | 'Skins' | 'All'} type
 *
 * @returns {AppState}
 */
export function clearSelectedProducts(state, type) {
    return produce(state, draftState => {
        switch (type) {
            case 'Sell':
                draftState.swapState.selectedProducts.sellProducts = [];
                break;
            case 'Purchase':
                draftState.swapState.selectedProducts.purchaseProducts = [];
                break;
            case 'Skins':
                draftState.swapState.selectedProducts.sellSkinsProducts = [];
                break;
            default:
                draftState.swapState.selectedProducts.sellProducts = [];
                draftState.swapState.selectedProducts.purchaseProducts = [];
                draftState.swapState.selectedProducts.sellSkinsProducts = [];
                break;
        }
    });
}

/**
 * @param {AppState} state
 * @param {'Sell' | 'Purchase' | 'Skins'} type
 * @param {'Add' | 'Remove'} action
 * @param {Product | SteamInventoryItem} product
 *
 * @returns {AppState}
 */
export function updateSelectedProducts(state, type, action, product) {
    return produce(state, draftState => {
        let productList;

        if (type === 'Skins') {
            productList = draftState.swapState.selectedProducts.sellSkinsProducts;
        } else if (type === 'Purchase') {
            productList = draftState.swapState.selectedProducts.purchaseProducts;
        } else {
            productList = draftState.swapState.selectedProducts.sellProducts;
        }

        if (action === 'Add') {
            const index = productList.findIndex(x => type === 'Skins' ? x.productId === product.productId && x.assetId === product.assetId : x.id === product.id && x.serviceFullName === product.serviceFullName);

            if (index === -1) {
                productList.push(product);
            } else {
                productList = productList.map((arrProduct, loopIndex) => {
                    if (loopIndex === index) {
                        return product;
                    }

                    return arrProduct;
                });

                if (type === 'Skins') {
                    draftState.swapState.selectedProducts.sellSkinsProducts = productList;
                } else if (type === 'Purchase') {
                    draftState.swapState.selectedProducts.purchaseProducts = productList;
                } else {
                    draftState.swapState.selectedProducts.sellProducts = productList;
                }
            }
        } else {
            const filteredProducts = productList.filter(x => type === 'Skins' ? x.productId !== product.productId || x.assetId !== product.assetId : x.id !== product.id || x.serviceFullName !== product.serviceFullName);

            if (type === 'Skins') {
                draftState.swapState.selectedProducts.sellSkinsProducts = filteredProducts;
            } else if (type === 'Purchase') {
                draftState.swapState.selectedProducts.purchaseProducts = filteredProducts;
            } else {
                draftState.swapState.selectedProducts.sellProducts = filteredProducts;
            }
        }
    });
}

/**
 * @param {AppState} state
 * @param {SteamInventoryItem | null} item
 */
export function setSelectedItem(state, item) {
    return produce(state, draftState => {
        draftState.swapState.selectedItem = item;
    });
}

export default initialState;
